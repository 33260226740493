import React from 'react';
import Customers from '../Customers/Customers';
import CustomersOrig from '../Customers/CustomersOrig'
import Config from '../../config';
import { sql } from '../../Utils'
import { withRouter } from 'react-router-dom';
import { Axios } from '../../Utils';
import { Button, Grid, Typography } from '@material-ui/core';

class Main extends React.Component {
    
    state = { layout: 'new', dbInitialized: false, l: null, exp: null, dest: null, config: null, selected: null }

    constructor(props) {
        super(props);

        this.db = Config.db.name;
    }
        
    initDb =  () => {
        sql.createDB({  db: 'posta' })
        console.log("db done!")
        return Promise.resolve(true)
    }   

    initTables = async () => {
        let promise = null
        try {
            promise = await Config.db.tables.map( async e => {
                return await sql.createTable({
                    db: Config.db.name,
                    table: e.name,
                    data: e.model
                })
            })
        } catch(err) { console.log("init tables failed: ", err)}
        finally {
            Promise.all(promise).then( () =>  { 
                console.log("tables done!")    
                return true 
            })
        }
    }


    getData = async () => {
        let promises = []
        const { db } = Config
        let keys = db.tables.map( elem => elem.name )
        console.log("keys:", keys)
        try {
            promises = await keys.map( async (key) => {
                return {
                    name: key, 
                    data: await sql.select({ db: db.name, table: key, data: 'all'})
                }
            })
        } catch(err) { console.log("data retrival error:", err)}
        finally {
            return Promise.all(promises)
                .then(  r =>  
                    r.reduce( (acc,elem)  =>  {
                        return {...acc, [elem.name]: elem.data}
                    }, {})
                )
                
        }
    }

    


    // async componentWillMount() {
    //     let result = null;
    //     try {
    //         result = await Axios({
    //             method: 'POST',
    //             url: '/is-token-valid'
    //         })
    //     } catch (err) {
    //         console.log("token error: ", err)
    //     }
    //     finally {
    //         console.log("is token valid:", result)
    //         if (!(result && result.data && result.data.id)) { this.props.history.push('/login/not-logged-in') }
    //     }

    // }



    async componentDidMount() {
       
        
            let promises = [], l = null, data, db, tables;
            try {
                data = await this.initDb()
                tables = await this.initTables()
            } catch(err) { console.log("mounting error: ", err) }
            finally {
                Promise.all([db,tables])
                    .then( async () => {
                        this.getData().then( r => {
                            console.log("r:", r)
                            this.setState({...r, l: true})
                        })
                    })
            }


        


    }

    render() {
        const { layout } = this.state
        return (
            <div>
                {/* <Grid container justify = "space-between" alignItems = "center">
                     <Grid item>
                        <Typography variant = "caption" style = {{ paddingLeft: '100px', color: '#f22d1e'}}>
                            Începând cu 20.05.2019 vă punem la dispoziție versiunea 2.1 - <strong>NOU!</strong> SETARILE PENTRU PLICURI INCLUD SI POSIBILITATEA LISTARII CU <strong style = {{color: 'navy'}}>IMPRIMANTE INKJET </strong> (aliniere plic la stanga/dreapta)!!!
                        </Typography>
                    </Grid>
          

                </Grid> */}
                <div>
                    {this.state.l
                        ? this.state.layout === 'old' ? <CustomersOrig {...this.state} /> : <Customers {...this.state} />
                        : (<div>Loading data...</div>)
                    }
                </div>

            </div>
        )
    }
}

export default withRouter(Main);
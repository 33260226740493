import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import { sql, destructure, restructure } from '../../../Utils';
import Config from '../../../config';
import { withRouter } from 'react-router-dom';
import ColorPicker from 'material-ui-color-picker';
import { Button, Switch } from '@material-ui/core';
import { FaCheck, FaArrowCircleUp, FaArrowCircleDown,  FaArrowCircleLeft,  FaArrowCircleRight } from 'react-icons/fa';
import FancyInput from '../../Common/FancyInput'

const styles = theme => ({
    root: {
        flexGrow: 1,

    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',

    },
    landscape: {
        // width: 276 / 1.5 + 'mm',
        // height: 190 / 1.5 + 'mm',
        width: '100%', height: '35vw',
        background: 'lightgrey',
        paddingRight: '10px'
    },
    paper: {
        padding: theme.spacing.unit * 1.2,
        height: '100%',

        marginLeft: '5px', marginRight: '5px',
        // marginLeft: 'auto', marginRight: 'auto'
    },
    center: {
        // marginLeft: 'auto', marginRight: 'auto'
    },
    FancyInput: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '120px'
    },
    dense: {
        marginTop: 16,
    },
    colorPicker: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        color: 'black',
        background: 'none'

    },
    small: {
        padding: '5px 0 5px 20px', color: 'navy'
    },
    arrow: {
        paddingTop:'2px', margin: 0, border: 'none', background: 'none'
    }
});

class EnvelopePreview extends React.Component {

    // state = { ...this.props, default: { ...this.props }, preview: null, explode: destructure({ ...this.props }) }
    state = { default: null, preview: null, explode: null, dataLoaded: null }

    getItem = (param) => {
        sql.select({ db: Config.db.name, table: 'config', data: { key: 'format', value: param } })
            .then(result => {
                console.log(result)
                this.setState({
                    default: result[0],
                    preview: null,
                    explode: destructure(result[0]),
                    dataLoaded: true
                })
                
            })
    }

    componentWillReceiveProps(newProps) {
        console.log("new PROPS:", newProps);
        this.getItem(newProps.match.params.format)
    
    }


    getFlex = (printerType) => {
        let flexType
        switch(printerType) {
            case 'laser' : flexType = "center"; break;
            case 'inkjet1' : flexType = "flex-start"; break;
            case 'inkjet2' : flexType = "flex-end"; break;
            default : flexType = 'center'
        }
        return flexType
    }

    handleChange = (name, sub) => event => {
        console.log(name,sub)
        let explode = this.state.explode;
        if (sub) {
            if (sub === 'fontWeight' || sub === 'border' || sub === 'background') {
                explode[name][sub] = event.target.value;
            } else {
                explode[name][sub].val = event.target.value;
            }
        } else {
            if ( explode[name] && explode[name]['val'] ) {
                explode[name]['val'] =  event.target.value
            } else {
                explode[name] = { val:  event.target.value }
            }
          
        }
        this.setState({ explode: { ...explode }, preview: restructure(explode) })
    }

    handleColor = event => {
        console.log("handle color:");
        console.log(" event: ", event)
        let explode = this.state.explode;
        explode.box.background = event;
        this.setState({ explode: { ...explode }, preview: restructure(explode) })

    }

    handleDefault = data => event => {
        
        let explode = this.state.explode;
        explode.default = event.target.checked;
        this.setState({explode:explode})
    }

    confirmHandler = async () => {
        // console.log("settings have been confirmed")
        let newData = restructure({ ...this.state.explode });
        let result = null, rm = null, nulDefaults = null;
        try {
            rm = await sql.remove({ db: Config.db.name, table: 'config', data: { key: 'id', value: newData.id } })
            nulDefaults = await sql.freestyle('posta', `UPDATE config SET [default] = false WHERE id != "${newData.id}"`)
            result = await sql.insert({ db: Config.db.name, table: 'config', data: newData })
        } catch (err) { console.log(err) }
        finally {
            alert('modificarile au fost efectuate!')
            this.setState({ preview: newData })
            this.props.history.replace({
                pathname: "/settings/styles/",
                params: { format: null }

            });
        }
    }

    resetToDefaultHandler = () => {
        this.setState({
            preview: null,
            explode: destructure({ ...this.state.data })
        })
    }

    handleMoveBox =  (type, direction) => event => {
        let explode = this.state.explode;
        // console.log(type,direction)
        switch (true) {
            case direction === 'up' :
                if (explode[type]['top']['val'] -5  !== 0 ) {
                    explode[type]['top']['val'] =explode[type]['top']['val'] - 5
                }
            break;
            case direction === 'down' :
                explode[type]['top']['val'] =explode[type]['top']['val'] + 5
            break;  
            case direction === 'left' && type === 'exp' :
                if (explode[type]['left']['val'] - 5 != 0) {
                    explode[type]['left']['val'] =  explode[type]['left']['val'] - 5
                }
            break;   
            case direction === 'right' && type === 'exp' :
                explode[type]['left']['val'] =explode[type]['left']['val'] + 5
            break;   
            case direction === 'left' && type === 'dest' :
                console.log("left dest")
                explode[type]['right']['val'] =explode[type]['right']['val'] + 5
            break;   
            case direction === 'right' && type === 'dest' :
                explode[type]['right']['val'] =explode[type]['right']['val'] - 5
            break;                                  
        }
        this.setState( {explode: explode, preview: restructure(explode) } )
        
    } 

    render() {
        const { classes } = this.props;
        const { explode, dataLoaded } = this.state
        // console.log("----------->envelope STATE: ", this.state)
        // console.log("----------->envelope PROPS: ", this.props)

        return (
            <div>
                {dataLoaded
                    ? (

                        <Grid
                            container
                            className={classes.root}
                            spacing={0}
                            direction="row"
                            justify="center"
                            alignItems="stretch"

                        >

                            <Grid item xs={3}>
                                <Grid container direction="column">
                                    <Paper className={classes.paper + ' ' + classes.center}>

                                        {/* title + confirm button */}
                                        <Paper className={classes.paper} style={{ margin: '5px 0 15px 0' }}>
                                            <Grid container direction="row" justify="space-between">
                                                <FancyInput
                                                    variant="outlined"
                                                    margin="none"
                                                    size="small" label="Denumire model"
                                                    value={this.state.explode.format.val}
                                                    onChange={this.handleChange('format')} />

                                                <Fab
                                                    variant="round"
                                                    color="secondary"
                                                    size = "medium"
                                                    style={{ paddingTop: '7px' }}
                                                    onClick={() => { this.confirmHandler(); }} >
                                                    <FaCheck style={{ fontSize: '2em', marginTop: '-3px' }} />
                                                </Fab>
                                            </Grid>
                                        </Paper>

                                        {/* E N V E L O P E   P R E V I E W */}

                                        {/* <Grid item> */}
                                        {/* dimensiuni plic */}
                                        <Paper>
                                            <small className={classes.small}><strong>Dimensiuni plic</strong></small><br />
                                            <Grid container direction='row' justify="space-between" alignItems="stretch" >

                                                {/* <Paper> */}

                                                {/* inaltime */}
                                                <FancyInput
                                                    label={'Înălțime (' + this.state.explode.height.unit + ')'}
                                                    value={this.state.explode.height.val}
                                                    onChange={this.handleChange('height')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />

                                                <FancyInput
                                                    label={'Lățime (' + this.state.explode.width.unit + ')'}
                                                    value={this.state.explode.width.val}
                                                    onChange={this.handleChange('width')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Paper>
                                        {/* end dimensiuni plic */}

                                        {/* dimensiuni eticheta */}
                                        <Paper>
                                            <small className={classes.small}><strong>Dimensiuni etichetă</strong></small><br />
                                            <Grid container direction='row' justify="space-between" alignItems="stretch" >
                                                {/* inaltime */}
                                                <FancyInput
                                                    label={'Înălțime (' + this.state.explode.box.height.unit + ')'}
                                                    value={this.state.explode.box.height.val}
                                                    onChange={this.handleChange('box', 'height')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                <FancyInput
                                                    label={'Lățime (' + this.state.explode.box.width.unit + ')'}
                                                    value={this.state.explode.box.width.val}
                                                    onChange={this.handleChange('box', 'width')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Paper>
                                        {/* end dimensiuni caseta adresa */}

                                        {/* pozitie expeditor */}
                                        <Paper>
                                            <small className={classes.small}><strong>Poziție caseta expeditor</strong></small><br />
                                            <Grid container direction='row' justify="space-between" alignItems="stretch" >

                                                {/* stanga */}
                                                <FancyInput
                                                    label={'Stânga (' + this.state.explode.exp.left.unit + ')'}
                                                    value={this.state.explode.exp.left.val}
                                                    onChange={this.handleChange('exp', 'left')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                {/* sus */}
                                                <FancyInput
                                                    label={'Sus (' + this.state.explode.exp.top.unit + ')'}
                                                    value={this.state.explode.exp.top.val}
                                                    onChange={this.handleChange('exp', 'top')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Paper>
                                        {/* end pozitie expeditor */}

                                        {/* pozitie destinatar */}
                                        <Paper>
                                            <small className={classes.small}><strong>Poziție caseta destinatar</strong></small><br />
                                            <Grid container direction='row' justify="space-between" alignItems="stretch" >
                                                {/* stanga */}
                                                <FancyInput
                                                    label={'Dreapta (' + this.state.explode.dest.right.unit + ')'}
                                                    value={this.state.explode.dest.right.val}
                                                    onChange={this.handleChange('dest', 'right')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                {/* sus */}
                                                <FancyInput
                                                    label={'Sus (' + this.state.explode.dest.top.unit + ')'}
                                                    value={this.state.explode.dest.top.val}
                                                    onChange={this.handleChange('dest', 'top')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Paper>
                                        {/* end pozitie destinatar */}

                                    </Paper>
                                </Grid>
                            </Grid>
                            {/* end left grid item */}

                            {/**************************************** start preview - middle grid item */}
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <Grid container direction="column" justify={this.getFlex(explode.printerType ? explode.printerType.val : 'center')} alignItems="flex-end" className={classes.landscape}>
                                    
                                        {/* <Grid item></Grid> */}
                                        <Grid item >

                                            <div style={{
                                                height: '' + this.state.explode.height.val / 2 + this.state.explode.height.unit,
                                                width: '' + this.state.explode.width.val / 2 + this.state.explode.width.unit,
                                                border: '1px dashed navy',
                                                background: '#ffffff'
                                            }}>

                                                {/* expeditor box */}
                                                <div 
                                                    style={{
                                                        position: 'relative',
                                                        borderRadius: '10px',
                                                        left: '' + this.state.explode.exp.left.val / 2 + this.state.explode.exp.left.unit,
                                                        top: '' + this.state.explode.exp.top.val / 2 + this.state.explode.exp.top.unit,
                                                        border: '' + this.state.explode.box.border,
                                                        height: '' + this.state.explode.box.height.val / 2 + this.state.explode.box.height.unit,
                                                        width: '' + this.state.explode.box.width.val / 2 + this.state.explode.box.width.unit,
                                                        background: this.state.explode.box.background ? this.state.explode.box.background : 'none'

                                                }} >
                                                    <Grid
                                                        container direction="column"
                                                        justify="center"
                                                        alignItems="center"
                                                        style={{
                                                            height: '' + this.state.explode.box.height.val / 2 + this.state.explode.box.height.unit,
                                                        }}>
                                                        <div></div>
                                                        <div style={{
                                                            fontSize: '' + this.state.explode.box.fontSize.val + this.state.explode.box.fontSize.unit,
                                                            fontWeight: this.state.explode.box.fontWeight,
                                                            textAlign: 'center',
                                                            lineHeight:1.5
                                                        }}>
                                                            <button onClick = { this.handleMoveBox('exp', 'up')}
                                                            className = {classes.arrow}> <FaArrowCircleUp style = {{fontSize: '1.2em', color: 'navy'}}/> </button>
                                                            <br/>
                                                            <button onClick = { this.handleMoveBox('exp', 'left')}
                                                            className = {classes.arrow}> <FaArrowCircleLeft  style = {{fontSize: '1.2em', color: 'navy'}}/> </button> 
                                                             <strong>  EXPEDITOR</strong>
                                                             <button onClick = { this.handleMoveBox('exp', 'right')}
                                                            className = {classes.arrow}> <FaArrowCircleRight  style = {{fontSize: '1.2em', color: 'navy'}}/></button> 
                                                            <br/>
                                                            <button onClick = { this.handleMoveBox('exp', 'down')}
                                                            className = {classes.arrow}> <FaArrowCircleDown  style = {{fontSize: '1.2em', color: 'navy'}}/> </button> 
                                                    </div>
                                                        <div></div>
                                                    </Grid>

                                                </div>

                                                {/* destinatar box */}
                                                <div style={{
                                                    position: 'relative',
                                                    borderRadius: '10px',
                                                    right: '' + this.state.explode.dest.right.val / 2 + this.state.explode.dest.right.unit,
                                                    top: '' + this.state.explode.dest.top.val / 2 + this.state.explode.dest.top.unit,
                                                    border: this.state.explode.box.border,
                                                    height: '' + this.state.explode.box.height.val / 2 + this.state.explode.box.height.unit,
                                                    width: '' + this.state.explode.box.width.val / 2 + this.state.explode.box.width.unit,
                                                    background: this.state.explode.box.background ? this.state.explode.box.background : 'none'

                                                }} >

                                                    <Grid
                                                        container direction="column"
                                                        justify="center"
                                                        alignItems="center"
                                                        style={{

                                                            height: '' + this.state.explode.box.height.val / 2 + this.state.explode.box.height.unit,
                                                        }}>
                                                        <div></div>
                                                        <div style={{
                                                            fontSize: '' + this.state.explode.box.fontSize.val + this.state.explode.box.fontSize.unit,
                                                            fontWeight: this.state.explode.box.fontWeight,
                                                            textAlign: 'center',
                                                            lineHeight:1.5
                                                        }}>
                                                            <button onClick = { this.handleMoveBox('dest', 'up')}
                                                            className = {classes.arrow}> <FaArrowCircleUp style = {{fontSize: '1.2em', color: 'navy'}}/> </button>
                                                            <br/>
                                                            <button onClick = { this.handleMoveBox('dest', 'left')}
                                                            className = {classes.arrow}> <FaArrowCircleLeft  style = {{fontSize: '1.2em', color: 'navy'}}/> </button> 
                                                             <strong>  DESTINATAR</strong>
                                                             <button onClick = { this.handleMoveBox('dest', 'right')}
                                                            className = {classes.arrow}> <FaArrowCircleRight  style = {{fontSize: '1.2em', color: 'navy'}}/></button> 
                                                            <br/>
                                                            <button onClick = { this.handleMoveBox('dest', 'down')}
                                                            className = {classes.arrow}> <FaArrowCircleDown  style = {{fontSize: '1.2em', color: 'navy'}}/> </button> 
                                                    </div>
                                                        <div></div>
                                                    </Grid>

                                                </div>

                                            </div>
                                        </Grid>
                                        {/* <Grid item></Grid> */}
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* end envelope preview */}

                            <Grid item xs={3}>
                                <Grid container direction="column">
                                    <Paper className={classes.paper + ' ' + classes.center}>
                                        {/* seteaza default */}
                                        <Paper>
                                        <small className={classes.small}><strong>Folosește ca format implicit</strong></small><br />
                                            <Switch
                                                checked={this.state.explode.default}
                                                onChange={this.handleDefault('default')}
                                                value="default"
                                                color="primary"
                                            />
                                            <small>Acest model 
                                                {this.state.explode.default ? '' : ' nu '}
                                                va fi folosit implicit la listare</small>
                                        </Paper>

                                        {/* fonturi box */}
                                        <Paper>
                                            <small className={classes.small}><strong>Stil fonturi</strong></small><br />
                                            <Grid container direction='row' justify="space-between" alignItems="stretch" >
                                                <Grid item xs = {6}>
                                                {/* font size */}
                                                <FancyInput
                                                    label={'Mărime (' + this.state.explode.box.fontSize.unit + ')'}
                                                    value={this.state.explode.box.fontSize.val}
                                                    onChange={this.handleChange('box', 'fontSize')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                </Grid>
                                                {/* font weight */}
                                                <Grid item xs = {6}>
                                                <FancyInput
                                                    label={'Îngroșare'}
                                                    value={parseInt(this.state.explode.box.fontWeight)}
                                                    onChange={this.handleChange('box', 'fontWeight')}
                                                    type="number"
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                </Grid>
                                                {/* border */}
                                                <Grid item xs = {12}>
                                                <FancyInput
                                                    label="Atribute margine"
                                                    value={this.state.explode.box.border}
                                                    onChange={this.handleChange('box', 'border')}
                                                    type="text"
                                                    className={classes.FancyInput}
                                                    style={{ width: '90%' }}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                                </Grid>
                                                {/* label color */}
                                                <Grid item xs = {12}>
                                                <ColorPicker
                                                    name='color'
                                                    // label = "alegeți o culoare"
                                                    floatingLabelText="Culoare fundal etichetă"
                                                    // style = {{color:'black'}}
                                                    defaultValue={this.state.explode.box.background}
                                                    onChange={this.handleColor}
                                                    FancyInputProps={{ margin: 'normal', variant: 'outlined', className: classes.colorPicker }}
                                                />
                                                </Grid>

                                                {/* asezare in pagina imprimanta */}
                                                <Grid item xs = {12}>
                                                <FancyInput
                                                    label="Tip imprimanta"
                                                    value = {this.state.explode.printerType ? this.state.explode.printerType.val : 'laser'}
                                                    onChange={this.handleChange('printerType', '')}
                                                    select
                                                    fullWidth
                                                    className={classes.FancyInput}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    SelectProps={{
                                                        native: true 
                                                    }}
                                                >
                                                    <option key ="laser" value = "laser">LASER</option>
                                                    <option key ="inkjet1" value = "inkjet1">INKJET 1</option>
                                                    <option key ="inkjet2" value = "inkjet2">INKJET 2</option>
                                                </FancyInput>
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                        {/* end fonturi box */}
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Grid>) : <div> DUBLU CLICK PENTRU A ALEGE O OPTIUNE! </div>}
            </div>
        )
    }
}
export default withRouter(withStyles(styles)(EnvelopePreview));
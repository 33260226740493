import axios from 'axios';
import Config from '../../config';

const Axios = () => {

    const conf = Config[process.env.NODE_ENV];
    // console.log("conf:", conf)
   
    const defaultOptions = {
        baseURL: conf.OAUTH_API + '/api/v1',
        headers: {
          'Content-Type': 'application/json',
        },
      };

    let instance = axios.create(defaultOptions);

    instance.interceptors.request.use(function (config) {
    
        let ls = localStorage.getItem(conf.NAMESPACE);
    
        if ( !ls ) { ls = {token: ''}} else {
            ls = JSON.parse(ls)
            config.headers['x-auth-token'] =  ls.token;
        }

        config.headers['APP'] = conf.APP_NAME;
        return config;

    });
  return instance;
}

export default Axios();
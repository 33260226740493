import React from 'react';
import './SecondToolbar.css';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid'

const styles = {
    root: {
        flexGrow: 1,
        
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap'

    },
    grow: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
       background: 'rgb(241, 241, 248)'
    },

};

const SecondToolbar = (props) => {
    const { classes } = props;
    return (
        <div className = "no-print">
        <AppBar position="static" color = "default" className = {classes.root}>
            <Toolbar className={classes.grow} color = "primary">
                <Grid container direction = "row" justify = "space-between" alignItems = "center">

                    {props.children}
                </Grid>    

            </Toolbar>
        </AppBar>
        </div>
    )
    }

export default withRouter(withStyles(styles)(SecondToolbar));
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';

import { sql } from '../../../Utils';
import Config from '../../../config';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    root: { flexGrow: 1 },
    paper: {
        padding: "10px",
        margin: '25px'
    },
    paper2: { margin: theme.spacing.unit*1.2, padding:  theme.spacing.unit*2, background: 'white' },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        textAlign: 'center'
      },
})

class SetDocs extends React.Component {

    state = {
        dataLoaded: false,
        tables: [
            { name: 'config', label: "Configurări", checked: false },
            { name: 'dest', label: "Destinatari", checked: false },
            { name: 'exp', label: "Expeditori", checked: false },
            { name: 'selected', label: "Selecție existentă", checked: true },
        ],
        status: null,
        agreement: ''
    }

    checkHandler = name => event => {
        console.log(name, event);
        let tables = this.state.tables;
        let elem = tables.filter((e, i) => e.name === name.name)[0]
        if (name.name !== 'selected') {
            tables[tables.indexOf(elem)].checked = !name.checked;
            this.setState({ tables: tables })
        }

    }

    deleteHandler = async () => {
        if (this.state.agreement === 'SUNT DE ACORD') {
            let promise = {};
            try {
                await this.state.tables.map( (e, i) => {
                    if (!!e.checked) {
                        
                            console.log(e.name)
                            promise[i] =  sql.remove({ db: Config.db.name, table: e.name, data: 'all' })
                        
                    }

                })
            } catch (err) { console.log(err); this.setState({ status: 'a intervenit o eroare...' }) }
            finally {
                if ( (this.state.tables.filter( e => e.name === 'config' && !!e.checked)).length>0) {
                    sql.insert({ db: Config.db.name, table:'config', data: Config.config }) .then( () => {
                        this.setState({ status: 'toate operațiunile au fost efectuate cu succes, configurarile au fost restaurate!' })
                    }) 
                } else {
                    this.setState({ status: 'toate operațiunile au fost efectuate cu succes!' })
                }
            }

        }
    }
    
    agreementHandler = event => {
        console.log("target",event)
         this.setState({agreement: event.target.value})
    }

    componentDidMount() {
        this.setState({ ...this.props, dataLoaded: true })
    }
    render() {
        const { classes } = this.props;
        // console.log("STATE", this.state)
        return (
            <div>
                {this.state.dataLoaded 
                    ? (
                        <Grid container direction="column" className={classes.root}>
 
                                <Paper className={classes.paper}>
                                <Typography variant='subtitle2' style={{ textAlign: 'center', color: 'navy' }}> INIȚIALIZARE APLICAȚIE </Typography>
                                {   !this.state.status
                                ? (                                
                                   <Paper className={classes.paper2}>
                                        <Typography variant='body2' style={{ color: 'red', fontWeight: 400, textAlign: 'justify' }}>
                                            Acestă procedură va elimina toate datele existente din tabelele selectate mai jos și va aduce aplicația la starea ei inițială.<br />
                                            Operațiunea nu este reversibilă, datele se vor pierde definitiv, motiv pentru care vă recomandăm ca, înainte de rulare, să
                                            efectuați un backup complet al datelor Dvs. (în format json)
                                        </Typography>
                                    </Paper>
                                ) : null
                            }
                            {   !this.state.status
                               ? (<Paper className={classes.paper2}>
                                    <Grid container direction="row" justify='space-evenly'>
                                        {this.state.tables.map((e, i) => (
                                            <Grid item key={'checked-' + i} >
                                                <Checkbox
                                                    checked={e.checked}
                                                    disabled={e.table === 'selected'}
                                                    onChange={this.checkHandler(e)}
                                                />{e.label}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Paper>): null
                                }
                                   <Paper className={classes.paper2}>
                                        {!this.state.status 
                                        ? (<Grid container direction="column" alignItems='center'>
                                            Pentru a confirma operația, scrieți în căsuța de mai jos textul (cu majuscule)<br/> 
                                            <strong>SUNT DE ACORD</strong>
                                            <TextField 
                                                margin="normal"
                                                variant="outlined"
                                                value = {this.state.agreement} 
                                                onChange = {this.agreementHandler}
                                                className={classes.textField}
                                            />
                                            <br/>
                                            { this.state.agreement === 'SUNT DE ACORD' 
                                                ? <Button variant = "contained" color="secondary" size='large' onClick = {this.deleteHandler}>CONFIRMĂ!</Button>
                                                : null
                                            }
                                        </Grid>) 
                                        : ( 
                                            <div>
                                            <Typography variant = 'body2' color='primary' style = {{textAlign: "center"}}> {this.state.status}</Typography>
                                            <Link to = '/'>ÎNAPOI LA APLICAȚIE</Link>
                                            </div>
                                        )}
                                    </Paper>
                            </Paper>

                        </Grid>
                    ) : null}
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(SetDocs));
/* eslint-disable */

const Config = {
    production: {
        APP_NAME    : 'CORES.WEBLOGIN.RO', 
        OAUTH_API   : "https://api.weblogin.ro", 
        OAUTH_APP   : "https://weblogin.ro",
        NAMESPACE   : "cores.weblogin.ro"
    },
    development: {
        APP_NAME    : 'CORES.WEBLOGIN.RO',
        OAUTH_API   : "https://api.weblogin.ro", 
        // OAUTH_API   : "http://localhost:3200",
        OAUTH_APP   : "https://weblogin.ro",
        NAMESPACE   : "cores.weblogin.ro"
    },
    db: {
        name: 'posta',
        tables: [
            {   name: 'exp',
                model: '(id string, name string, str string, nr string, bl string, et string, sc string, ap string, \
                    cp string, loc string, com string, jud string, sect string, tara string )' 
            },
            {   name: 'dest',
                model: '(id string, name string, str string, nr string, bl string, et string, sc string, ap string, \
                    cp string, loc string, com string, jud string, sect string, tara string )' 
            },
            {   name: 'selected',
                model: '(id string, selectedId string, type string, item string )' 
            },
            {   name: 'preset',
                model: '(id string, item string )' 
            },
            {   name: 'config',
                model: '(id string, format string, height string, width string, isdefault string, \
                    exp string, dest string, box string )' 
            }            
        ]
    },
    // preset: [],
    dest: [{
        "name": "CONSILIUL JUDEȚEAN ARAD",
        "str": "CORNELIU COPOSU",
        "nr": "22",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310003",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "3c41987e-30bb-4631-940c-c070d544b189"
    }, {
        "name": "PRIMĂRIA MUNICIPIULUI ARAD",
        "str": "BULEVARDUL REVOLUŢIEI ",
        "nr": "75",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310130",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "0f5085c7-5e29-4c70-97a3-d69a94b9da4d"
    }, {
        "name": "DIRECȚIA GENERALĂ A FINANȚELOR PUBLICE ARAD",
        "str": "BULEVARDUL REVOLUŢIEI ",
        "nr": "79",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310130",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "993ff3e4-90d2-4315-a3a2-7bbaa70412b7"
    }, {
        "name": "INSPECTORATUL ȘCOLAR JUDEȚEAN ARAD ",
        "str": "CORNELIU COPOSU",
        "nr": "26",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310003",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "82f33019-406e-446d-80d8-1c515c58beb7"
    }, {
        "name": "INSPECTORATUL PT SITUAȚII DE URGENȚĂ ARAD",
        "str": "ANDREI SAGUNA",
        "nr": "66-72",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310037",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "d4a22604-56b7-4f04-9b8b-738aa509e6f8"
    }, {
        "name": "PREFECTURA ARAD",
        "str": "BD. REVOLUȚIEI",
        "nr": "75",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310130",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "db612790-f85d-48e4-a243-608b448ac8fc"
    }, {
        "name": "DIRECŢIA PENTRU AGRICULTURĂ ARAD",
        "str": "CLOȘCA",
        "nr": "6A",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310017",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "81976f14-ce47-42f2-b67b-398663966e14"
    }, {
        "name": "TRIBUNALUL ARAD",
        "str": "VASILE MILEA",
        "nr": "2",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310131",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "0cd03fba-7209-4aed-a717-048580685887"
    }, {
        "name": "JUDECĂTORIA CHIȘINEU-CRIȘ",
        "str": "GĂRII",
        "nr": "24",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "315100",
        "loc": "CHIȘINEU-CRIȘ",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "d766a90b-3f30-446c-bff9-6a9539248758"
    }, {
        "name": "JUDECĂTORIA INEU",
        "str": "NARCISELOR",
        "nr": "7",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "315300",
        "loc": "INEU",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "bb4114cb-bb77-47e2-8e5f-338da5bc046e"
    }, {
        "name": "JUDECĂTORIA GURAHONȚ",
        "str": "CRIȘAN",
        "nr": "12",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "317145",
        "loc": "GURAHONȚ",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "bbe02e06-43c3-4896-87f6-e11fc30cb896"
    }, {
        "name": "JUDECĂTORIA LIPOVA",
        "str": "SAVA BRANCOVICI",
        "nr": "27",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "315400",
        "loc": "LIPOVA",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "1ecdabe9-6e68-4b7a-8f64-c54108bd5f1a"
    }, {
        "name": "DIRECȚIA DE SĂNĂTATE PUBLICĂ ARAD",
        "str": "ANDREI ŞAGUNA",
        "nr": "1-3",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310037",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "1dd04017-33ba-4d2a-a70b-b7847d5387ca"
    }, {
        "name": "INSPECTORATUL JUDEȚEAN DE POLIȚIE ARAD",
        "str": "VÂRFUL CU DOR",
        "nr": "17-21",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310037",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "09b1dfbf-7037-403f-aa08-7612b13a720b"
    }, {
        "name": "CASA JUDEȚEANĂ DE PENSII ARAD",
        "str": "VOLUNTARILOR",
        "nr": "2A",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310157 ",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "1498fdfe-0edb-4a07-901b-83f0873df78c"
    }, {
        "name": "DIRECTIA GENERALA DE ASISTENTA SOCIALA SI PROTECTIA COPILULUI ARAD",
        "str": "1 DECEMBRIE 1918",
        "nr": "14",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310134",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "66ff81ab-4bbd-4441-8cc9-caf3c22a29ad"
    }, {
        "name": "COMPANIA DE TRANSPORT PUBLIC ARAD SA",
        "str": "CALEA VICTORIEI",
        "nr": "35B-37",
        "bl": "",
        "et": "",
        "sc": "",
        "ap": "",
        "cp": "310158",
        "loc": "ARAD",
        "com": "",
        "jud": "ARAD",
        "sect": "",
        "tara": "ROMÂNIA",
        "id": "80e89c1c-f670-43d9-ac28-add3c96fa964"
    }, {
        "name": "PRIMARIA ALMAS",
        "loc": "ALMAS",
        "str": "PRINCIPALA",
        "nr": 605,
        "id": "ecb96eef-354e-401d-aa9f-e30215e25f70",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317005,
        "com": "ALMAS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA APATEU",
        "loc": "APATEU",
        "str": "PRINCIPALA",
        "nr": 364,
        "id": "15b6e1da-9097-47f8-b92f-a90671878484",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317010,
        "com": "APATEU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA ARCHIS",
        "loc": "ARCHIS",
        "str": "PRINCIPALA",
        "nr": 97,
        "id": "0ea84f22-db1a-49ef-935c-de6e8196d21e",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317015,
        "com": "ARCHIS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BATA",
        "loc": "BATA",
        "str": "PRINCIPALA",
        "nr": 166,
        "id": "2fb7d59a-38a1-4e85-b65b-29ef2dbd94dc",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317020,
        "com": "BATA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BELIU",
        "loc": "BELIU",
        "str": "PRINCIPALA",
        "nr": 632,
        "id": "664bf99f-cff0-4c78-aa6d-e8a90ce9d16d",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317040,
        "com": "BELIU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BIRCHIS",
        "loc": "BIRCHIS",
        "str": "PRINCIPALA",
        "nr": "161",
        "id": "721b8278-e307-422e-9679-77e9967d05e2",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317050,
        "com": "BIRCHIS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BIRSA",
        "loc": "BIRSA",
        "str": "PRINCIPALA",
        "nr": 125,
        "id": "3d742506-8d2f-4d62-a306-5f89de0348a5",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317025,
        "com": "BIRSA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BIRZAVA",
        "loc": "BIRZAVA",
        "str": "PRINCIPALA",
        "nr": 314,
        "id": "65447081-8f96-4727-ac4a-aa82608f3450",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317030,
        "com": "BIRZAVA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BOCSIG",
        "loc": "BOCSIG",
        "str": "PRINCIPALA",
        "nr": 490,
        "id": "f2cd3610-4f43-4ed0-8693-ce7a41824448",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317055,
        "com": "BOCSIG",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BRAZII",
        "loc": "BRAZII",
        "str": "PRINCIPALA",
        "nr": 26,
        "id": "30ccdb77-2543-440c-97cb-021e7eb57c9f",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317060,
        "com": "BRAZII",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA BUTENI",
        "loc": "BUTENI",
        "str": "PRINCIPALA",
        "id": "b37481fa-fe09-42f4-aa68-1761245b497a",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317065,
        "com": "BUTENI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA CARAND",
        "loc": "CARAND",
        "str": "PRINCIPALA",
        "nr": 50,
        "id": "22161a5f-a001-4af7-833f-f49cec9e1fb3",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317070,
        "com": "CARAND",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA CERMEI",
        "loc": "CERMEI",
        "str": "PRINCIPALA",
        "nr": 343,
        "id": "d7f67158-a0d3-4851-9e42-a7369de9e2ee",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317076,
        "com": "CERMEI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA CHISINDIA",
        "loc": "CHISINDIA",
        "str": "PRINCIPALA",
        "nr": "191A",
        "id": "43e3c42a-e644-4ebb-85f5-f7e87d6ba773",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317080,
        "com": "CHISINDIA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA CONOP",
        "loc": "CONOP",
        "str": "PRINCIPALA",
        "id": "a119cf94-5904-469f-a516-23cd0a3c9841",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317085,
        "com": "CONOP",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA COVASINT",
        "loc": "COVASINT",
        "str": "PRINCIPALA",
        "id": "af1c3b24-0c54-41c3-b8b6-e76ba94c7b6e",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317090,
        "com": "COVASINT",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA CRAIVA",
        "loc": "CRAIVA",
        "str": "PRINCIPALA",
        "nr": 118,
        "id": "4565b3b2-02a8-43f8-8dcb-10590b1e3c1e",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317095,
        "com": "CRAIVA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA DEZNA",
        "loc": "DEZNA",
        "str": "PRINCIPALA",
        "nr": 63,
        "id": "cf83d161-efe5-4b0b-b4f0-5e6c68c51f03",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317110,
        "com": "DEZNA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA DIECI",
        "loc": "DIECI",
        "str": "PRINCIPALA",
        "nr": 137,
        "id": "ffe53b28-8072-476a-a4c4-8123d1c5601d",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317115,
        "com": "DIECI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA DOROBANTI",
        "loc": "DOROBANTI",
        "str": "DOROBANTI",
        "nr": 410,
        "id": "f5690d53-500e-4315-adbc-c47994b7cd5a",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 315201,
        "com": "DOROBANTI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA FELNAC",
        "loc": "FELNAC",
        "str": "PRINCIPALA",
        "nr": 330,
        "id": "001e3006-6f0f-4137-b072-d768c42ce677",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317125,
        "com": "FELNAC",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA FINTINELE",
        "loc": "FINTINELE",
        "str": "PRINCIPALA",
        "id": "a0853dc2-af97-4aaa-834e-af32b88f3a6d",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317120,
        "com": "FINTINELE",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA GHIOROC",
        "loc": "GHIOROC",
        "str": "CALEA ARADULUI",
        "nr": 89,
        "id": "89ea3c98-0f0a-434d-9334-d28b7d94950a",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317135,
        "com": "GHIOROC",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA GRANICERI",
        "loc": "GRANICERI",
        "str": "PRINCIPALA",
        "nr": 10,
        "id": "b594cb1b-76a6-44cc-b8b7-08a2f56a091a",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317140,
        "com": "GRANICERI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA GURAHONT",
        "loc": "GURAHONT",
        "str": "PRINCIPALA",
        "id": "4b32b5c8-5a41-4b73-af03-7b79bd1b7474",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317145,
        "com": "GURAHONT",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA HALMAGEL",
        "loc": "HALMAGEL",
        "str": "PRINCIPALA",
        "nr": 1,
        "id": "6f84d484-ad14-4cb5-8954-fb4b2264180d",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317180,
        "com": "HALMAGEL",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA HALMAGIU",
        "loc": "HALMAGIU",
        "str": "PRINCIPALA",
        "nr": 158,
        "id": "0a8604ee-01a0-4129-8db2-0c235bdef991",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317160,
        "com": "HALMAGIU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA HASMAS",
        "loc": "HASMAS",
        "str": "PRINCIPALA",
        "nr": 216,
        "id": "031f1444-b587-418e-99f7-3efd14d45701",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317185,
        "com": "HASMAS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA IGNESTI",
        "loc": "IGNESTI",
        "str": "PRINCIPALA",
        "nr": 24,
        "id": "4c5c4bc8-9dc0-489a-a8c6-c91ff6163f5a",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317195,
        "com": "IGNESTI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA IRATOSU",
        "loc": "IRATOSU",
        "str": "PRINCIPALA",
        "nr": 1,
        "id": "64d0a506-4c1e-496a-ad22-d3e41475c3ee",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317200,
        "com": "IRATOSU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA LIVADA",
        "loc": "LIVADA",
        "str": "PRINCIPALA",
        "nr": 1065,
        "id": "0ab7eb6e-bc05-45bb-93f2-49e8e9d68fd8",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317205,
        "com": "LIVADA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA MACEA",
        "loc": "MACEA",
        "str": "PRINCIPALA",
        "nr": 262,
        "id": "0930922a-9515-4d2c-b2b4-de97714dad40",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317210,
        "com": "MACEA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA MISCA",
        "loc": "MISCA",
        "str": "PRINCIPALA",
        "id": "fce97aae-29dd-4c5a-8909-4af18b723904",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317215,
        "com": "MISCA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA MONEASA",
        "loc": "MONEASA",
        "str": "PRINCIPALA",
        "nr": 71,
        "id": "a1e42073-ea27-4fbd-ab3c-540fc15a70dd",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317220,
        "com": "MONEASA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA OLARI",
        "loc": "OLARI",
        "str": "PRINCIPALA",
        "nr": 315,
        "id": "657ab1c4-302a-4ee9-80ab-c8ff64bc48ce",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317225,
        "com": "OLARI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA PAULIS",
        "loc": "PAULIS",
        "str": "PRINCIPALA",
        "nr": 1,
        "id": "ed762080-bde0-4fe6-a534-6d44beb7cfd7",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317230,
        "com": "PAULIS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA PECICA",
        "loc": "PECICA",
        "str": "PRINCIPALA",
        "nr": 274,
        "id": "4ed40875-d045-44b8-8304-46f8fe09186d",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317235,
        "com": "PECICA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA PEREGU MARE",
        "loc": "PEREGU MARE",
        "str": "PRINCIPALA",
        "nr": 137,
        "id": "b9f9763e-a0e9-4ed1-8bbe-d30ecef3b41d",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317240,
        "com": "PEREGU MARE",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA PETRIS",
        "loc": "PETRIS",
        "str": "PRINCIPALA",
        "nr": 19,
        "id": "5936da34-5eae-4a29-b83a-1be70f093d29",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317245,
        "com": "PETRIS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA PILU",
        "loc": "PILU",
        "str": "PRINCIPALA",
        "id": "1b012460-f1b8-449a-9a5b-ea7b459bdafe",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317255,
        "com": "PILU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA PLESCUTA",
        "loc": "PLESCUTA",
        "str": "PRINCIPALA",
        "nr": 85,
        "id": "5e14fd28-266c-4711-8807-cba8dcd4de59",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317260,
        "com": "PLESCUTA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SAGU",
        "loc": "SAGU",
        "str": "PRINCIPALA",
        "nr": 219,
        "id": "d4f945fc-b512-4a4f-8839-5929d0f418ce",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317310,
        "com": "SAGU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SAVIRSIN",
        "loc": "SAVIRSIN",
        "str": "ALBA IULIA",
        "nr": 37,
        "id": "0495b2b0-426c-424f-9eec-892b12376c98",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317270,
        "com": "SAVIRSIN",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SECUSIGIU",
        "loc": "SECUSIGIU",
        "str": "PRINCIPALA",
        "nr": 274,
        "id": "59793c76-c6ef-498c-9b04-650d9bef6b65",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317285,
        "com": "SECUSIGIU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SEITIN",
        "loc": "SEITIN",
        "str": "REVOLUTIEI",
        "nr": 47,
        "id": "2e79de4d-a3aa-464e-939d-fe6b200d1e9c",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317315,
        "com": "SEITIN",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SELEUS",
        "loc": "SELEUS",
        "str": "PRINCIPALA",
        "nr": 200,
        "id": "4b9f4e71-cc09-493f-abfe-884f09856f24",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317290,
        "com": "SELEUS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SEMLAC",
        "loc": "SEMLAC",
        "str": "PRINCIPALA",
        "nr": 656,
        "id": "4dea6a54-c525-4e64-8339-14e5510a924d",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317295,
        "com": "SEMLAC",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SEPREUS",
        "loc": "SEPREUS",
        "str": "PRINCIPALA",
        "nr": 298,
        "id": "13a48a70-21f1-4d38-9709-c09b43b20edc",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317320,
        "com": "SEPREUS",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SICULA",
        "loc": "SICULA",
        "str": "PRINCIPALA",
        "nr": 200,
        "id": "857d64dc-8b15-44b9-99aa-27c54102066f",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317325,
        "com": "SICULA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SILINDIA",
        "loc": "SILINDIA",
        "str": "PRINCIPALA",
        "nr": 384,
        "id": "f8b7f665-39a5-4bd8-ace3-4d996b664dfa",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317330,
        "com": "SILINDIA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SIMAND",
        "loc": "SIMAND",
        "str": "PRINCIPALA",
        "nr": 1119,
        "id": "4fd6c5e9-420f-416f-8469-cdc7cc57f024",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317335,
        "com": "SIMAND",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SINTEA MARE",
        "loc": "SINTEA MARE",
        "str": "PRINCIPALA",
        "id": "5d8b1079-7dc5-445e-a5f4-d3241be1242e",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317300,
        "com": "SINTEA MARE",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SIRIA",
        "loc": "SIRIA",
        "str": "REG.85 INFANTERIE",
        "nr": 184,
        "id": "c95eb3b9-8d7e-473c-aeca-f034957ff4ac",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317340,
        "com": "SIRIA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SISTAROVAT",
        "loc": "SISTAROVAT",
        "str": "PRINCIPALA",
        "nr": 116,
        "id": "d6c9eeb2-a07f-40a8-aad4-afc274e01a8a",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317345,
        "com": "SISTAROVAT",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SOCODOR",
        "loc": "SOCODOR",
        "str": "PRINCIPALA",
        "id": "3d43b5ea-55ef-4917-b1d7-87689c3f4298",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317305,
        "com": "SOCODOR",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SOFRONEA",
        "loc": "SOFRONEA",
        "str": "PRINCIPALA",
        "nr": 188,
        "id": "42428f93-4817-4aeb-aa00-cc80574aa6c0",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317305,
        "com": "SOFRONEA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA TAUT",
        "loc": "TAUT",
        "str": "PRINCIPALA",
        "nr": 613,
        "id": "1adf3acf-4624-4935-8d3c-f5da8fd8ebdc",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317355,
        "com": "TAUT",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA TIRNOVA",
        "loc": "TIRNOVA",
        "str": "PRINCIPALA",
        "nr": 734,
        "id": "edfe69c5-6e96-443d-be3a-92a39bf7fd7b",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317360,
        "com": "TIRNOVA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA USUSAU",
        "loc": "USUSAU",
        "str": "PRINCIPALA",
        "nr": 343,
        "id": "1b579041-aaed-470a-a430-8eded8898895",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317375,
        "com": "USUSAU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA VARADIA DE MURES",
        "loc": "VARADIA DE MURES",
        "str": "PRINCIPALA",
        "nr": 127,
        "id": "a9cef3b2-d9da-449f-83eb-754183583e1f",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317380,
        "com": "VARADIA DE MURES",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA VINGA",
        "loc": "VINGA",
        "str": "PRINCIPALA",
        "nr": 27,
        "id": "1d1a6de6-77b9-4b2f-bf12-4dedfc524fd7",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317400,
        "com": "VINGA",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA VIRFURILE",
        "loc": "VIRFURILE",
        "str": "PRINCIPALA",
        "nr": 291,
        "id": "4a1cfbce-0e47-43eb-8f8a-0a45128dd792",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317391,
        "com": "VIRFURILE",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA VLADIMIRESCU",
        "loc": "VLADIMIRESCU",
        "str": "REVOLUTIEI",
        "nr": 24,
        "id": "1b16e4ab-25bc-474b-b453-d20ff2bcb7e7",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317405,
        "com": "VLADIMIRESCU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA ZABRANI",
        "loc": "ZABRANI",
        "str": "PRINCIPALA",
        "nr": 95,
        "id": "8f90eb95-e9b6-4007-b391-931f6d6fa762",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317410,
        "com": "ZABRANI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA ZADARENI",
        "loc": "ZADARENI",
        "str": "PRINCIPALA",
        "id": "dd667c59-74b1-43e8-a476-a76ceadae565",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317130,
        "com": "ZADARENI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA ZARAND",
        "loc": "ZARAND",
        "str": "PRINCIPALA",
        "nr": 512,
        "id": "82f2c254-fdfd-44ab-a2ae-4224e499a8f7",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317415,
        "com": "ZARAND",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA ZERIND",
        "loc": "ZERIND",
        "str": "PRINCIPALA",
        "nr": 15,
        "id": "f21a9df5-77c0-4b3f-8c22-33e5cc5c80b0",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317420,
        "com": "ZERIND",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA ZIMANDU NOU",
        "loc": "ZIMANDU NOU",
        "str": "PRINCIPALA",
        "nr": 264,
        "id": "38126ab4-5c8e-4803-93e6-f9ba2ce7a98c",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317425,
        "com": "ZIMANDU NOU",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA ARAD",
        "loc": "ARAD",
        "str": "B-DUL REVOLUTIEI",
        "nr": 75,
        "id": "ccba1c05-43a0-493b-9982-0e566b2183e5",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": "310130  ",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SINTANA",
        "loc": "SINTANA",
        "str": "GHIOCEILOR",
        "nr": 42,
        "id": "c85051ca-a054-4739-978f-c3860c69a1a8",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317280,
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA FRUMUSENI",
        "loc": "FRUMUSENI",
        "str": "PRINCIPALA",
        "nr": 423,
        "id": "68185bf9-24ca-4b0e-8d8f-424c991e23b2",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 317122,
        "com": "FRUMUSENI",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA CHISINEU CRIS",
        "loc": "CHISINEU CRIS",
        "str": "PRINCIPALA",
        "nr": 97,
        "id": "21a63c95-2593-419e-a637-c02d54608dcc",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 315100,
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA CURTICI",
        "loc": "CURTICI",
        "str": "PRIMARIEI",
        "nr": 47,
        "id": "c777ab07-4f51-40a3-b464-96dcb7137f46",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "cp": 315200,
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA INEU",
        "loc": "INEU",
        "str": "REPUBLICII",
        "nr": 5,
        "id": "1e6abf01-e200-49a7-8e64-a004c2c0701e",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA LIPOVA",
        "loc": "LIPOVA",
        "str": "NICOLAE BALCESCU",
        "nr": 26,
        "id": "6497386c-afb4-4396-a85d-8f271d06a149",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA NADLAC",
        "loc": "NADLAC",
        "str": "1 DECEMBRIE",
        "nr": 24,
        "id": "ee556271-01dc-462b-bb4e-48af9f4d385c",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA PINCOTA",
        "loc": "PINCOTA",
        "str": "TUDOR VLADIMIRESCU ",
        "nr": 68,
        "id": "43d1c300-7888-40f9-b7e7-90f60bdaf577",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }, {
        "name": "PRIMARIA SEBIS",
        "loc": "SEBIS",
        "str": "PRINCIPALA",
        "nr": 4,
        "id": "a1cdb0e9-5a0e-4078-a732-5eb7bcf7952f",
        "bl": "---",
        "et": "---",
        "sc": "---",
        "ap": "---",
        "jud": "ARAD ",
        "sect": "---",
        "tara": "ROMANIA"
    }],
    exp: [{
        "name": "SIGN PORTAL SRL ARAD",
        "str": "Spl.G-ral Gh.Magheru",
        "nr": "---",
        "bl": "304",
        "et": "7",
        "sc": "B",
        "ap": "26",
        "cp": "310329",
        "loc": "Arad",
        "com": "---",
        "jud": "Arad",
        "sect": "---",
        "tara": "Romania",
        "id": "21bde22d-bb93-4a8d-b50c-706e52314d9f"
    }],
    config: [{
        "format": "C5",
        "height": "162mm",
        "width": "229mm",
        "default": false,
        "exp": {
            "left": "0px",
            "top": "20px"
        },
        "dest": {
            "right": "-450px",
            "top": "180px"
        },
        "box": {
            "width": "320px",
            "height": "180px",
            "fontSize": "14px",
            "fontWeight": 200,
            "background": "none",
            "border": "2px dashed black"
        },
        "id": "0c453713-171b-42f6-8863-5eed3edb580f"
    }, {
        "format": "DL",
        "height": "110mm",
        "width": "220mm",
        "default": false,
        "exp": {
            "left": "20px",
            "top": "20px"
        },
        "dest": {
            "right": "-470px",
            "top": "50px"
        },
        "box": {
            "width": "320px",
            "height": "150px",
            "fontSize": "14px",
            "fontWeight": 200,
            "border": "2px dashed black"
        },
        "id": "e9a70ea6-8b0a-4236-b15b-58aa25601734"
    }, {
        "format": "B6",
        "height": "125mm",
        "width": "176mm",
        "default": false,
        "exp": {
            "left": "20px",
            "top": "10px"
        },
        "dest": {
            "right": "-300px",
            "top": "110px"
        },
        "box": {
            "width": "320px",
            "height": "150px",
            "fontSize": "14px",
            "fontWeight": 200,
            "border": "2px dashed black"
        },
        "id": "e7a6fc63-d163-49f0-a540-ed2e0208ea0f"
    }, {
        "format": "C6",
        "height": "114mm",
        "width": "162mm",
        "default": true,
        "exp": {
            "left": "20px",
            "top": "10px"
        },
        "dest": {
            "right": "-290px",
            "top": "110px"
        },
        "box": {
            "width": "280px",
            "height": "130px",
            "fontSize": "12px",
            "fontWeight": 200,
            "border": "2px dashed black"
        },
        "id": "aa01da18-3203-43d0-bb10-7f40d0657f55"
    }],
    selected: []

}

export default Config
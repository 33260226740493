import React from 'react';
import { Link } from 'react-router-dom';
import './ToolbarTop.css';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import logo from '../../../assets/img/logo.png'

const styles = {
    root: {
        flexGrow: 1,

    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',

    },

};

const ToolbarTop = (props) => {
    const linkHandler = () => { props.history.push('/')}
    
    const { classes } = props;
    return (
        <div className = "no-print">
        <AppBar position="static" color = "default" className = {classes.root}>
            <Toolbar color = "primary" >
                
                <Grid container direction = "row" justify = "space-between" alignItems = "center" style = {{paddingLeft:'80px'}}>
                
                <img src = {logo} style = {{position:'fixed', top:0, left: 10, zIndex: 99999, height: '80px', width: 'auto'}} onClick = {linkHandler}/>
                  
                {props.children}    
                
                </Grid>
            </Toolbar>
        </AppBar>
        </div>
    )
}

export default withRouter(withStyles(styles)(ToolbarTop));
import React from 'react';
import './AdressLabel.css'
const AddressLabel = (props) => {
    return (
        <div className="box" style={{ position: 'relative', ...props.box}}>
        <span style={{ margin: '10px 0 0 10px', position: 'relative' }}>
            <strong>{props.type === 'exp' ? 'Expeditor' : 'Destinatar'}</strong>
            <hr/>
            
            <strong className = "title">{props.name}</strong>
            
        </span>
        <br/><br/>
        <span style={{ margin: '0px 0 0 10px', position: 'relative' }}>
            <small>str. </small>
            <strong> {props.str ?props.str : '---'}</strong>
        </span>
        <span style={{ margin: '20px 0 0 10px', position: 'relative' }}>
            <small>nr. </small>
            <strong> {props.nr ?props.nr : '---'}</strong>
        </span>
        <br />
        <span style={{ margin: '0 0 0 10px', position: 'relative' }}>
            <small>bl. </small>
            <strong> {props.bl ?props.bl : '---'}</strong>
        </span>
        <span style={{ margin: '0px 0 0 10px', position: 'relative' }}>
            <small>et. </small>
            <strong> {props.et ?props.et : '---'}</strong>
        </span>
        <span style={{ margin: '0px 0 0 10px', position: 'relative' }}>
            <small>sc.</small>
            <strong> {props.sc ?props.sc : '---'}</strong>
        </span>
        <span style={{ margin: '0px 0 0 10px', position: 'relative' }}>
            <small>ap.</small>
            <strong> {props.ap ?props.ap : '---'}</strong>
        </span>
        <br />

        <span style={{ margin: '0 0 0 10px', position: 'relative' }}>
            <small>localitatea</small>
            <strong> {props.loc ?props.loc : '---'}</strong>
        </span>
        { !props.sect ? (
            <span>
        <span style={{ margin: '0 0 0 10px', position: 'relative' }}>
            <small>comuna</small>
            <strong> {props.com ?props.com : '---'}</strong>
        </span>
        <br />
        </span>
        ):null }
        <span style={{ margin: '0 0 0 10px', position: 'relative' }}>
            <small>cod postal </small>
            <strong>{props.cp ?props.cp : '---'}</strong>
        </span>
        <span>
        { props.jud && (
            <span style={{ margin: '0 0 0 10px', position: 'relative' }}>
                <small>judetul &nbsp;</small>
                <strong> {props.jud ? props.jud : '---'} </strong>
            </span>
        )}
        </span>
        <span>
        { props.sect && props.sect !== '---' && (
            <span style={{ margin: '0 0 0 10px', position: 'relative' }}>
                <small>sectorul &nbsp;</small>
                <strong> {props.sect ? props.sect : '---'} </strong>
            </span>
        )}
        </span>
        <br/>
        <br/>
    </div>         
    )
}

export default AddressLabel;
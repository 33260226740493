import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Config from '../../config';
import ToolbarTop from '../Layout/ToolbarTop/ToolbarTop';
import MainContent from '../Layout/MainContent/MainContent';
import Grid from '@material-ui/core/Grid';
import './Borderou.css';

const styles = {
root: { flexGrow: 1 },
center: { textAlign: 'center', margin: 'auto', width:'100%' },

}

const split = (arr, n) => {
var res = [];
while (arr.length) {
res.push(arr.splice(0, n));
}
return res;
}

const printHandler = () => {
window.print();
}

class Borderou extends React.Component {

// state = { ...this.props}

linkHandler = name => event => {
    this.props.history.push({
    pathname: name,
    state: { dest: this.props.location.state.dest, exp: this.props.location.state.exp }
    })
}

render() {
console.log(this.props)
const { classes } = this.props;
let dest = this.props.location.state.dest;
let exp = this.props.location.state.exp[0].item;
let chunks = split([...dest], 10);
let i = 1, segment = null, segmentIndex = null;
chunks.map( (chunk,iChunk) => {
segment = chunk;
segmentIndex = iChunk
chunk.map( (item, iItem) => {

chunks[segmentIndex][iItem]['item']['nrcrt'] = i;
++i;

})
})

console.log(chunks)
return (

<div>

    <div className="no-print">
        <ToolbarTop>

            <div>
                <button className="btn-default-format" style={{ float: 'right' }} onClick={this.linkHandler(
                    '/envelope' )}>PLICURI
                </button>
                <button className="btn-default-format" style={{ float: 'right' }} onClick={this.linkHandler( '/receipt'
                    )}>CONFIRMARI
                </button>
                <button className="btn-default-format" style={{ float: 'right' }} onClick={this.linkHandler( '/' )}>HOME
                </button>
            </div>
            <div></div>
            <div>
                <button className="btn-default-format" style={{ width: '100px' }} onClick={printHandler}>PRINT
                </button>
            </div>

        </ToolbarTop>
    </div>

    <div>

        { chunks.map( (elem,index) => {

        return (
        <div className={'landscape'} key={'landscape-'+index}>
            <Grid container direction="column" justify="center" alignItems="flex-end">
                <small style={{textAlign:'right'}}> pagina {index+1} din {chunks.length}</small>
                <table className='table'>
                    <thead>
                        <tr>
                            <td style={{paddingleft: '20px'}} colSpan="2">
                                <p>Beneficiar <br /><strong>{exp.name}</strong></p>
                                <p>Adresa:<br /> str. <strong>{exp.str}</strong>, nr. <strong>{exp.nr}</strong>, bl.
                                    <strong>{exp.bl}</strong>, sc. <strong>{exp.sc}</strong>, et. <strong>{exp.et}</strong>,
                                    ap. <strong>{exp.ap}</strong>,<br /> loc. <strong>{exp.loc}</strong>, com. <strong>{exp.com}</strong>,
                                    jud. <strong>{exp.jud}</strong>, cod poștal <strong>{exp.cp}</strong>,</p>
                            </td>
                            <td colSpan="4"><strong> BORDEROU<br />Pentru trimiteri de recomandate prezentate &icirc;n
                                    serie</strong></td>
                            <td colSpan="5">Borderou nr .........<br /> de trimiteri recomandate prezentate &icirc;n
                                serie la oficiul poştal ..........<br />&icirc;n ziua ......... luna ......... anul
                                ...................</td>
                        </tr>
                        <tr>
                            <td rowSpan="2">
                                <p>Nr.crt.</p>
                            </td>
                            <td rowSpan="2">
                                <p>Denumirea sau numele complet al destinatarului</p>
                            </td>
                            <td rowSpan="2">Destinatia&nbsp;</td>
                            <td rowSpan="2">
                                <p>Servicii suplimen- tare</p>
                                &nbsp;
                            </td>
                            <td rowSpan="2">Greutate&nbsp;</td>
                            <td colSpan="2">
                                <p>Tarife<br />(scutit de TVA fara drept de deducere)</p>
                            </td>
                            <td colSpan="2">Tarife<br />(cu TVA inclus)</td>
                            <td rowSpan="2">Nr.prezentare&nbsp;</td>
                            <td rowSpan="2">Obs.&nbsp;</td>
                        </tr>
                        <tr>
                            <td>lei</td>
                            <td>B</td>
                            <td>lei</td>
                            <td>B</td>
                        </tr>
                        <tr>
                            <td style={{width: '5%'}}><small>1</small></td>
                            <td style={{width: '20%'}}><small>2</small></td>
                            <td style={{width: '15%'}}><small>3</small></td>
                            <td style={{width: '5%'}}><small>4</small></td>
                            <td style={{width: '5%'}}><small>5</small></td>
                            <td style={{width: '5%'}}><small>6</small></td>
                            <td style={{width: '5%'}}>&nbsp;</td>
                            <td style={{width: '5%'}}><small>7</small></td>
                            <td style={{width: '5%'}}>&nbsp;</td>
                            <td style={{width: '15%'}}><small>8</small></td>
                            <td style={{width: '15%'}}><small>9</small></td>
                        </tr>
                    </thead>
                    <tbody>
                        { elem.map( (e,i) => (
                        <tr key={'tableitem-'+i}>
                            {/* <td className="td-center">{index === 0 ? i+1 : index+''+(i+1) }</td> */}
                            <td className="td-center">{e.item.nrcrt}</td>
                            <td className = "text-left">{e.item.name}</td>
                            <td className = "text-left">{e.item.loc}, JUD. {e.item.jud} </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr> ))
                        }
                    </tbody>
                </table>

            </Grid>
            <Grid container direction="row" justify="space-around" alignItems="center">
                <div style={{textAlign:'center'}}>
                    <br /><br />
                    <div style={{ textAlign:'center'}}>
                        <div style={{display:'inline-block',height: '40px', width:'40px',border: '.07em dashed black', borderRadius:'40px', textAlign:'center'}} />
                    </div>
                    Ștampila beneficiarului,

                    <div style={{ textAlign:'center'}}>
                        <div style={{display:'inline-block',height: '40px', width:'40px',border: '.07em dashed black', borderRadius:'40px', textAlign:'center'}} />
                    </div>
                    Ștampila cu data prezentării,

                </div>

                <div>
                    <br />
                    Eliberat chitanţa de la nr................ la nr................ <br />
                    (primul şi ultimul număr de prezentare)<br /><br />

                    Salariat poştal <br />
                    (numele şi prenumele, semnătura)

                </div>

            </Grid>
        </div>
        )

        })}

    </div>
</div>

)}
}

export default withRouter(withStyles(styles)(Borderou));
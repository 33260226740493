const Unit = (val) => {
    let result = { val: val, unit: null }
    const sizes = ["mm", "px", "pt", "em"];
    sizes.map(sizeUnit => {
        if (val.indexOf(sizeUnit) > 0 ) {
            // console.log(val, sizeUnit)
            result.unit = sizeUnit;
            result.val = parseFloat(val.replace(sizeUnit, ''))
        }
    })
    return result
}

const Destructure = (obj) => {
	const ExceptedKeys = ["fontWeight", "border", "default", "background", "color", "unit", "val", "id"]

	if ( typeof obj === 'object' ) {
        obj = {...obj}
        
        let keys = Object.keys(obj);
        
		// console.log("keys", keys)
		keys.map( key => {
			// console.log(key, typeof key)
			switch( true ) {
				case (ExceptedKeys.indexOf(key) > 0 ) : 
					break;					
				case (typeof obj[key] === 'object') :
					obj[key] = Destructure(obj[key]);
					break;
				case (typeof obj[key] === 'boolean'):
					break;
				case (typeof obj[key] === 'number'):
					break;
				case key === 'fontWeight' : 
					break;
				default : 
					obj[key] = Unit(obj[key])
					break;
			}

			// if ( typeof obj[key] !== 'object' ) {
				
			// 	if (( key in ExceptedKeys) ||  typeof obj[key] === 'boolean'  || typeof obj[key] === 'number') { 
			// 		if ( typeof obj[key] === 'number') { obj[key] = parseInt(obj[key])}

			// 	} else {
			// 		console.log(obj[key])
			// 		obj[key] = Unit(obj[key])
			// 	}
			// } else {
			// 	obj[key] = Destructure(obj[key])
			// }
		})
	}
	return obj
}

 export default Destructure

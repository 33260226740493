import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './components/Main/Main';
import Receipt from './components/Receipt/Receipt';
import Envelope from './components/Envelope/Envelope';
import Settings from './components/Settings/Settings';
import Borderou from './components/Borderou/Borderou';
import Labels from './components/Labels/Labels';
import Login from './components/Login/Login';

import About from './components/About/About';
class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/" exact component={Main} />
                <Route path="/receipt/"  component={Receipt} />
                <Route path="/envelope/"  component={Envelope} />
                <Route path="/settings/"  component={Settings} />
                <Route path="/borderou/"  component={Borderou} />
                <Route path="/labels"  component={Labels} />
                
                <Route path = "/login/:token" component = {Login} />
                <Route path="/about/"  component={About} />
            </Switch>
        )
        
    }
}

export default Routes;
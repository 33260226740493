import React from 'react';
import ToolbarTop from '../Layout/ToolbarTop/ToolbarTop';
import SecondToolbar from '../Layout/SecondToolbar/SecondToolbar';
import MainContent from '../Layout/MainContent/MainContent';
import Button from '@material-ui/core/Button';
import { Switch, Route, Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import SetInit from './SetInit/SetInit';
import SetStyles from './SetStyles/SetStyles';
import ExcelImport from './Excel/ExcelImport';
import ExcelExport from './Excel/ExcelExport';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Hidden from '@material-ui/core/Hidden';
import { Hide } from '../../Utils';
import { FaHome, FaArrowLeft } from 'react-icons/fa';
import {Styles} from './Experimental'

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    link: { textDecoration: 'none' }
});

class Settings extends React.Component {

    state = {
        ...this.props.location.data,
        menu: [
            { label: 'INIȚIALIZARE', short: 'Reset',  url: '/settings/init', path: './SetInit/SetInit' },
            { label: 'STILURI', short: 'Stil', url: '/settings/styles', path: './SetStyles/SetStyles' },
            { label: 'IMPORT DATE', short: 'Import', url: '/settings/excel/import', path: './Excel/ExcelImport' },
            { label: 'EXPORT DATE', short: 'Export', url: '/settings/excel/export', path: './Excel/ExcelExport' },
            { label: 'EXPERIMENTAL', short: 'Experimental', url: '/settings/experimental', path: './Experimental' },
        ],
        msg: { open: false, caller: '11', onClose: () => { } }

    }

    backHandler = () => {
        console.log(this.props)
        this.props.history.push({
            pathname: '/',
        })
    }

    render() {
        // console.log("settings state", this.state)
        const { classes } = this.props;
        // 
        return (

            <div>

                <ToolbarTop>
                    <Typography variant='subtitle2'>
                        CONFIGURARI APLICAȚIE
                    </Typography>
                    <div>
                        <Button color="primary" variant="text" size="small" onClick={() => this.props.history.goBack()}><FaArrowLeft style = {{fontSize:'2em'}}/></Button>
                        <Button color="primary" variant="text" size="small" onClick={this.backHandler}><FaHome style = {{fontSize:'2em'}}/></Button> &nbsp;
                            
                        &nbsp;&nbsp;&nbsp;
                        </div>

                </ToolbarTop>

                <SecondToolbar>

                    <div style={{ display: 'flex' }}>
                        {this.state.menu.map((elem, index) => (
                            <div key={'link-' + index}>
                                <Link to={elem.url} className={classes.link} >
                                   
                                    <Button
                                        variant={this.props.history.location.pathname === elem.url ? 'outlined' : 'text'}
                                        color={this.props.history.location.pathname === elem.url ? 'primary' : 'default'} >
                                         <Hidden only = "xs">
                                        <Typography variant='subtitle1'>
                                            {elem.label}
                                        </Typography>
                                        </Hidden>

                                        <Hidden only = {['lg', 'md', 'sm']}>
                                        <Typography variant='subtitle1'>
                                            {elem.short}
                                        </Typography>
                                        </Hidden>
                                        
                                    </Button>
                                    
                                </Link>
                            </div>
                        ))}
                    </div>
                    <dir></dir>
                </SecondToolbar>
                <MainContent>
                    <ConfirmDialog {...this.state.msg} />
                    <Switch>

                        <Route path='/settings/init' component={SetInit} />
                        <Route path='/settings/styles' component={SetStyles} />
                        <Route path='/settings/excel/import' component={ExcelImport} />
                        <Route path='/settings/excel/export' component={ExcelExport} />
                        <Route path='/settings/experimental' component={Styles} />

                    </Switch>

                </MainContent>
            </div>
        )
    }
}
export default withRouter(withStyles(styles)(Settings));
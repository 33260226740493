import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Config from '../../config';
import Cores from '../../assets/img/CORES.svg';

import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import { FaHome, FaChevronDown } from 'react-icons/fa'
import MDReactComponent from 'markdown-react-js';
import doc from "../../assets/doc/documentatie-cores.pdf"

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    paper: {
        //width: '100%',
        padding: theme.spacing.unit * 3,
        margin: theme.spacing.unit * 3,

    },
    center: { textAlign: 'center' },
    justify: { textAlign: 'justify', textJustify: 'inter-words' },
    left: { textAlign: 'left' },
    right: { textAlign: 'right' },
})

const data = [
{
    title: "Despre aplicație", 
    content : `
Pe la sfârșitul anului 2018, odată cu dezvoltarea afacerii și creșterea bruscă a numărului de clienți, firma noastră s-a lovit de o problemă majoră: 
corespondența comercială, juridică și financiar contabilă, pur și simplu ne lua **extrem de mult timp** !

Atunci am inceput să dezvoltăm o aplicație web (rudimentară, în prima fază) care să ne ajute să automatizăm corespondența prin Poșta Română 

- ***plicurilor***
- ***confirmărilor de primire*** (AR)
- ***borderourilor de expediție***

Discutănd cu unii clienți existenți despre "realizarea" noastră, am constatat că multă lumea avea aceeași problemă, așa că am decis să o oferim tuturor, ca un serviciu pe bază de abonament lunar. 

(realizarea și dezvoltarea continuă a unei astfel de aplicații presupune multă muncă și unele provocări neobișnuite, fiind puși în postura de inova în continuu)
`},
{
    title: "Licențe și condiții de utilizare", 
    content : `Portalul cores.weblogin.ro este dezvoltat de compania **SIGN Portal srl** din Arad. ***\([signportal.ro](https://signportal.ro)\)***

Serviciile de gestiune a utilizatorilor, găzduire, dezvoltare, actualizare a aplicației aferente serviciului, vânzări și relații cu clienții sunt asigurate de 
***MAFTEI GABRIEL-CLAUDIU PFA ARAD***
    `
}, 
{
    title: "Prelucrarea datelor cu caracter personal", 
    content : `SIGN Portal srl și Maftei Gabriel-Claudiu p.f.a. tratează cu maximă responsabilitate problematica datelor personale așa cum este ea reglementată de 
    Regulamentul general privind protecția datelor (UE) 2016/679 
    
Din acest motiv, tehnologia aleasă în implementarea acestui serviciu web permite stocarea tuturor datelor aplicației EXCLUSIV pe calculatorul clientului, și anume 
    prin tehnologia \"LOCAL STORAGE\" din browser-ul Google Chrome
    
Singurele date culese și procesate cu ocazia utilizării aplicației sunt datele Dvs. de acces și valabilitate a abonamentului, stabilite de comun acord prin contractul de prestari servicii informatice încheiat între
Maftei Gabriel-Claudiu p.f.a. și entitatea Dvs. juridică`
},
{
    title: "Documentație de utilizare",
    content: `Descărcați ultima versiune a manualului de utlizare al serviciului cores.weblogin.ro și a aplicației web aferente acestuia [AICI](${doc})`
},
{
    title: "Informațiii de contact & suport", 
    content : `## MAFTEI GABRIEL-CLAUDIU PERSOANĂ FIZICĂ AUTORIZATĂ ##


| | |
|---|---|
| *adresa:* | ***spl. G-ral Gh.Magheru, bl.304, sc. B., et.7, ap.26, 310329 - Arad, România***|
| *telefon:* | ***0744.845.974, 0732.165.039*** |
| *email:* | ***gmaftei@gmail.com*** |



    `},
    // {title: "Versiune demonstrativa", content : <img src = {Cores}/>},

]

class About extends React.Component {

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props
        return (
            <Grid container direction="column" justify="center" alignItems="stretch" className={classes.root}>
                <Paper className={classes.paper}>

                    <Link to='/'>
                        <FaHome />
                    </Link>

                    <Typography variant='h3' className={classes.center}>
                        {Config[process.env.NODE_ENV]['APP_NAME']}
                </Typography><br/>
                    {data.map( (e,i)=> (
                        <ExpansionPanel key = {'expand-'+i}>
                        <ExpansionPanelSummary expandIcon={<FaChevronDown />}>
                            <Typography className={classes.heading}>{e.title}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                        <MDReactComponent text={e.content} />   

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    ))}

                    {/* <Tabs
                        value={this.state.value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChange}
                    />
                        <Tab label="Despre aplicație" />
                        <Tab label="Instrucțiuni de utilizare" />
                        <Tab label="Licențe și condiții de utilizare" />
                        <Tab label="Prelucrarea datelor cu caracter personal" />
                        <Tab label="Istoric versiuni" />
                        <Tab label="Contact" />

                        {this.state.value === 0 && <TabContainer>Page One</TabContainer>}
          {this.state.value === 1 && <TabContainer>Page Two</TabContainer>}
          {this.state.value === 2 && <TabContainer>Page Three</TabContainer>} */}

                </Paper>
            </Grid>
        )
    }
}

export default withStyles(styles)(About);
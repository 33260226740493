import React from 'react';
import { sql } from '../../../Utils'
import * as XLSX from 'xlsx';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Config from '../../../config';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
    },
    input: {
        display: 'none',
    },
    paper: {
        margin: theme.spacing.unit * 2,
        padding: theme.spacing.unit * 2,
    },
    center: { textAlign: 'center'}
});

const split = (arr, n) => {
    var res = [];
    while (arr.length) {
      res.push(arr.splice(0, n));
    }
    return res;
  }

class ExcelImport extends React.Component {

    models = {
        config: {
            id: 'string',
            format: 'string',
            height: 'string',
            width: 'string',
            exp: {
                left: 'string',
                top: 'string'
            },
            dest: {
                right: 'string',
                top: 'string'
            },
            isDefault: 'boolean',
            box: {
                width: 'string',
                height: 'string',
                fontSize: 'string',
                fontWeight: 'string'
            }
        },
        exp: [
            {
                name: 'string',
                str: 'string',
                bl: 'string',
                sc: 'string',
                et: 'string',
                ap: 'string',
                loc: 'string',
                com: 'string',
                jud: 'string',
                cp: 'string',
                tara: 'string'
            }
        ],
        dest: [
            {
                name: 'string',
                str: 'string',
                bl: 'string',
                sc: 'string',
                et: 'string',
                ap: 'string',
                loc: 'string',
                com: 'string',
                jud: 'string',
                cp: 'string',
                tara: 'string'
            }
        ],
        selected: [
            {
                id: 'string',
                selectedId: 'string',
                type: 'string',
                item: {
                    name: 'string',
                    str: 'string',
                    bl: 'string',
                    sc: 'string',
                    et: 'string',
                    ap: 'string',
                    loc: 'string',
                    com: 'string',
                    jud: 'string',
                    cp: 'string',
                    tara: 'string'
                }
            }
        ]

    }

    state = {
        data: null,
        models: this.models,
        done: false,
        progress: 0,
        status: null

    }

    xlsUploadHandler = (event) => {
        let f = event.target.files[0];
        var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_row_object_array(ws);
            this.setState({ tmpData: {dest:data} })
            this.setState({
                foundTables: [
                   
                    {item: 'dest', selected: true}, 
]
            });
        };
        reader.readAsBinaryString(f);
    }

    urlDecoder = ( arr ) => {
        return arr.map( item => {
            let keys = Object.keys(item)
            keys.map( key => item[key] = decodeURI(item[key]))
            return item
        })
    }

    jsonUploadHandler = (event) => {
        this.setState({ foundTables: null })
        let f = event.target.files[0];
        var name = f.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            let data = evt.target.result;
            data = (JSON.stringify(eval('(' + data + ')')));
            data = JSON.parse(data);
            // decode uri encoded values of dest end exp
            data.dest = data.dest ? this.urlDecoder(data.dest): []
            data.exp = data.exp ? this.urlDecoder(data.exp): []
            this.setState({ tmpData: data })
            const ModelKeys = Object.keys(this.state.models);
            let TheseKeys = Object.keys(data);
            TheseKeys.map((e, i) => {
                if (ModelKeys.indexOf(e) > 0 || !Array.isArray(e)) {
                    TheseKeys[i] = { item: e, selected: true, count: data[e].length }
                } else {
                    TheseKeys[i] = { item: e, selected: false, count: 0 }
                }
                return null;
            })
            this.setState({ foundTables: TheseKeys })

        };
        reader.readAsBinaryString(f);

    }

    chunkify = (arr, n) => {
        var res = [];
        while (arr.length) {
          res.push(arr.splice(0, n));
        }
        return res;
      }

    updateHandler = action => async () => {

        const { foundTables, tmpData } = this.state
        let promises = [], selectedTables = []
       
        selectedTables = foundTables.reduce( (acc=[], elem) => {
            if ( elem.selected ) {  acc.push(elem.item);  return acc }
        }, [])

        try {
            promises = await selectedTables.map( table => {
                return this.createOrUpdate(table,tmpData[table], action)
            })
        } catch (err) { console.log(err) }
        finally {
            Promise.all(promises).then( () => {
                console.log("all done!")
                this.setState({done: true})
            })
        }

    }

    createOrUpdate = async (table, data, action) => {
        // action = append/replace
        let finalPromise = null, removePromise = null, chunksPromise = null, chunks = []

        if ( action === 'replace' ) {
            removePromise = sql.remove({db: Config.db.name, table: table, data: 'all'}).then( ()=> Promise.resolve() ) 
        } else {
            removePromise = Promise.resolve()
        }
        try {
            finalPromise = await removePromise.then( async () => {
                chunks = this.chunkify(data, 100)
                try {
                    chunksPromise = await chunks.map( async (chunck, ichunck) => {
                        console.log("ichunk: ",ichunck, chunck)
                       
                        return await sql.insert({db: Config.db.name, table: table, data: chunck })
                      
                    })
                } catch(err) { console.log("chunks error:", err)}
                finally {
                    Promise.all([finalPromise]).then( r => { console.log("final:",r, "\n"); this.setState({done:true}) })
                }
            })
        } catch(err) { console.log("cOu error: ", err)}
        finally {
            return finalPromise
        }


    }


    importTestData = () => {
        const { db } = Config;
        const tables = ['dest', 'exp', 'config']
        let data = tables.reduce( (acc=[],item) => {
            acc[item] =  Config[item]
            return acc
        }, [])
        console.log("dr:",data)
        return this.setState({
            foundTables: tables.map(elem=>({item:elem, selected: true, count: elem.length})), 
            tmpData: data})

    }

    checkHandler = name => event => {
        // console.log(name, event.target.value, event.target.checked)

        let tables = this.state.foundTables
        tables.map((e, i) => {
            if (e === name) {
                tables[i].selected = !tables[i].selected
            }
        })
        this.setState({ foundTables: tables })

    }

    render() {
        // console.log(this.state.tmpData ? this.state.tmpData.dest : 'nu exista dest')
        console.log(this.state)
        const { classes } = this.props;
        const { status, progress, tmpData, done } = this.state
        const Keys = ["name", "str", "nr", "bl", "sc", "et", "ap", "cp", "loc", "com", "jud", "tara"]
        return (
            <Grid container direction = "column">
                <Paper className = {classes.paper}>
                
                <Grid>
                    {/* incarcare fisiere */}
                    {!tmpData && !done
                        ? (
                            <Grid item>
                                <Typography variant = 'h4' className = {classes.center}>
                                    IMPORT DATE DIN DIN FIȘIER DE BACKUP
                                </Typography>
                                <hr/>
                                <Grid container direction = "row" justify="space-around">
                                    <Grid item xs = {12}>
                                        <input
                                            accept=".xls, .xlsx"
                                            className={classes.input}
                                            id="xls-upload"
                                            type="file"
                                            onChange={this.xlsUploadHandler}
                                        />
                                            <label htmlFor="xls-upload">
                                                <Button variant="contained" color = "primary" size = "large" component="span" fullWidth className={classes.button}>
                                                    IMPORT CONTACTE DIN FIȘIER XLS/XLSX 
                                                </Button>
                                            </label>
                                    </Grid>
                                    <Grid item xs = {12}>
                                        <input
                                            accept="*/*"
                                            className={classes.input}
                                            id="json-upload"
                                            multiple
                                            type="file"
                                            onChange={this.jsonUploadHandler}
                                        />
                                            <label htmlFor="json-upload">
                                                <Button variant="contained" color = "primary" size = "large" component="span" fullWidth className={classes.button}> 
                                                    IMPORT COMPLET DIN FISIER JSON
                                                </Button>
                                            </label>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item xs = {12}>
                                    <Button variant = "contained"  color = "primary" size = "large" fullWidth className={classes.button}
                                        onClick = { this.importTestData }
                                    >
                                        importa date de test (institutii publice din județul Arad)
                                    </Button>

                                </Grid>

                            </Grid>
                        ) : null }
                    {/* end incarcare fisiere */}

                  
                </Grid>

                {/* display found tables && import actions                 */}
                <Grid>
                {
                    // display found tables
                    (!!this.state.foundTables && !this.state.done)
                        ? (
                            <Paper className={classes.paper}>
                                Fișierul de backup ales de Dvs. conține următoarele tabele:
                               <ul>
                                    {
                                        this.state.foundTables.map((e, i) => (
                                            <li key={'elem-' + i} >
                                                <input type="checkbox" checked={e.selected} onChange={this.checkHandler(e)}></input>
                                                {e.item} <i>({e.count} articole)</i>
                                            </li>
                                        ))
                                    }
                                </ul>
                                </Paper>
                            ) : null}

                         { this.state.tmpData && this.state.done === false 
                         ?
                            <Grid item>
                            <Paper className={classes.paper}>
                                <Typography variant ='headline' className={classes.center}>
                                Cum doriți să faceți importul datelor?
                                </Typography>
                                <br />
                                <Grid container direction = "row" justify="space-evenly" alignItems="stretch">
                                <Button
                                    onClick={this.updateHandler('replace')}
                                    variant="contained"
                                    color="secondary"
                                    style={{ width: '33%'}}
                                    size="small">
                                    CU ELIMINAREA DATELOR EXISTENTE
                                </Button>
                                <Button
                                    onClick={this.updateHandler('append')}
                                    variant="contained"
                                    color="primary"
                                    style={{ width: '33%' }}
                                    size="small">
                                    CU PĂSTRAREA DATELOR EXISTENTE
                                </Button>
                                </Grid>
                                </Paper>
                            </Grid>
                    : null }
                </Grid>
                <Grid container justify = "center">
                    {progress > 0 && ( <div>{progress}</div>)}
                </Grid>
                {/* arata dest ca tabel */}
                <Grid>
                {
                    // display for xls import only
                    (!!this.state.tmpData && !this.state.done && this.state.tmpData.dest && this.state.tmpData.dest.length < 1000 )
                        ? (
                            <Paper className={classes.root}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            {Keys.map((k, ii) => (
                                                <TableCell key={'th-' + ii}>{k}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style = {{height: '300px', overflowY: 'auto'}}>
                                        {this.state.tmpData.dest.map((row, ii) => {
                                            return (
                                                <TableRow key={'rr-' + ii}>
                                                    <TableCell key="firstrow">{ii + 1}</TableCell>
                                                    {Keys.map((k, ii) => (
                                                        <TableCell key={"k-" + ii}>{row[k]}</TableCell>
                                                    ))}

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>

                        ) : null }
                    </Grid>
               
                    { this.state.done 
                    ? <div className =  {classes.center}>
                        <h1>OPERAȚIUNEA DE IMPORT S-A FINALIZAT CU SUCCES !</h1>
                        <Button variant = 'contained' color='primary' onClick = {()=> this.props.history.push('/')}>INAPOI</Button>
                    </div>
                    : null}
                </Paper>
            </Grid>

        )
    }
}

export default withStyles(styles)(ExcelImport);
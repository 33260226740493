import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
// import '../../App.css';

const styles = {
    root: { flexGrow: 1 },
    input: {
        width: '90px'
    }
}

const LabelsLeftPanel = (props) => {
    const { classes } = props;
    console.log("left PROPS", props)

    return (

        <Grid container direction="row" justify='space-between' alignItems='center'>
            <Grid item>
                <Button 
                    onClick = {props.orientationHandler('landscape')}
                    size = "small"
                    variant = {props.orientation === 'landscape' ? "contained" : "outlined"}
                    color = {props.orientation === 'landscape' ? "primary" : "default"} >
                    LANDSCAPE
                </Button>
                <Button 
                    onClick = {props.orientationHandler('portrait')}
                    size = "small"
                    variant = {props.orientation === 'portrait' ? "contained" : "outlined"}
                    color = {props.orientation === 'portrait' ? "primary" : "default"} >
                    PORTRAIT
                </Button>                
            </Grid>
        {/* coloane, randuri */}
            {/* <Grid item>
                <Grid container direction="row">
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        label="coloane"
                        type="number"
                        onChange={props.changeHandler('cols')}
                        value={props.cols} />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        label="randuri"
                        type="number"
                        onChange={props.changeHandler('rows')}
                        value={props.rows} />
                </Grid>
            </Grid> */}

            {/* label size */}
            <Grid item>
                <Grid container direction="row">
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        label="Lățime (mm)"
                        type="number"
                        onChange={props.changeHandler('sizeX')}
                        value={props.sizeX} />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        label="Înălțime(mm)"
                        type="number"
                        onChange={props.changeHandler('sizeY')}
                        value={props.sizeY} />
                </Grid>
            </Grid>

            {/* distantiere*/}
            <Grid item xs = {8}>
                <Grid container direction="row">
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        label="Stânga"
                        type="number"
                        onChange={props.changeHandler('left')}
                        value={props.left} />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        label="Dreapta"
                        type="number"
                        onChange={props.changeHandler('right')}
                        value={props.right} />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        label="Sus"
                        type="number"
                        onChange={props.changeHandler('top')}
                        value={props.top} />                        
                
                <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="normal"
                        size= "small"
                        label="Jos"
                        type="number"
                        onChange={props.changeHandler('bottom')}
                        value={props.bottom} />   
                </Grid>             
            </Grid>            
        </Grid>

    )
}

export default withStyles(styles)(LabelsLeftPanel);
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom';
import {  Typography, Button, Hidden, Paper, Grid, withStyles } from '@material-ui/core';
import { FaCog, FaQuestionCircle, FaPowerOff, FaTimesCircle, FaArrowLeft, FaArrowRight, FaPlus, FaEnvelopeOpen, FaReplyAll, FaAddressCard } from 'react-icons/fa'
import ReactTable from "react-table"
import SelCustomer from './SelCustomer/SelCustomer';
import ToolbarTop from '../Layout/ToolbarTop/ToolbarTop';

import MainContent from '../Layout/MainContent/MainContent';
import AddCustomer from '../AddCustomer/AddCustomer';
import EditCustomer from '../EditCustomer/EditCustomer';
import 'react-table/react-table.css'

import { sql } from '../../Utils';
import Config from '../../config';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Presets from '../../components/Presets'


const styles = theme => ({
    root: { flexGrow: 1 },
    paper: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit
    }
})

class Customers extends Component {
    state = {
        ...this.props,
        search: '',
        addModal: false,
        editModal: false,
        removeConfirmation: false,
        initial: { ...this.props },
        msg: { open: false, caller: null, onClose: () => { } },
        selectAll: false,
        loading: false,
        currentItem: null,
        presetsOpen: false,
        presets: [],
        filtered: []
    }
    componentWillMount() {
        let ls = localStorage.getItem(Config[process.env.NODE_ENV]['NAMESPACE'])
        if (ls) {
            ls = JSON.parse(ls)
            console.log(ls)
            this.setState({ user: ls })
        }
    }

    repopulate = async () => {
        let dest = null, exp = null, selected = null, selExp = null, selDest = null, presets = null;
        console.log("pres:", Config.db.tables['preset'])
        try {
            dest = await sql.select({ db: Config.db.name, table: 'dest', data: 'all' })
            exp = await sql.select({ db: Config.db.name, table: 'exp', data: 'all' })
            selected = await sql.select({ db: Config.db.name, table: 'selected', data: 'all' })
            presets = await sql.createTable({ db: Config.db.name, table: 'preset', data: "(id string, item string )" })
        } catch (err) { console.log("data read error:",err) }
        finally {
            Promise.all([dest,exp, selected, presets]).then( 
                () => {
                    this.setState({ dest: dest, exp: exp, initial: { dest: dest, exp: exp, presets: presets  } });
                    if (selected.length > 0) {
                        selected.map((e, i) => {
                            if (e.item) {
                                selected[i].item = this.state[e.type].filter(elem => elem.id === e.item.id)[0];
                            }
                        })
                    }
                    sql.freestyle(Config.db.name, 'DELETE FROM selected WHERE id NOT NULL')
                        .then(r => {
                            sql.insert({ db: Config.db.name, table: 'selected', data: selected }).then(rs => {
                                console.log("selected was updated. result...", rs);
                                this.setState({ selected: selected, editModal: false })
                            })
                        })
                }
            )

        }
    }

    contactHandler = (event, type) => async () => {
        // console.log("contact handler event: ", event, "\ntype: ", type)
        let result = null;
        // daca este expeditor, sterge toti expeditorii din selected si apoi insereaza event-ul
        if (type === 'exp') {
            sql.remove({ db: Config.db.name, table: 'selected', data: { key: 'type', value: 'exp' } })
        }

        // if is not there already, add it
        if (this.state.selected.filter(e => e.selectedId === event.id).length === 0) {
            try {
                result = await sql.insertGetId({
                    db: Config.db.name,
                    table: 'selected',
                    data: { selectedId: event.id, type: type, item: event }

                })
            } catch (err) { console.log("err:", err); }
            finally {

                sql.select({ db: Config.db.name, table: 'selected', data: 'all' }).then(r => this.setState({ selected: r }))
            }
        }
        else {
            //else remove it (does not matter wheather is dest of exp)
            try {
                await sql.remove({
                    db: Config.db.name,
                    table: 'selected',
                    data: { key: 'selectedId', value: event.id }
                })
            } catch (err) { console.log("err", err) }
            finally {
                sql.select({ db: Config.db.name, table: 'selected', data: 'all' }).then(r => this.setState({ selected: r }))
            }

        }

        // this.setState({ search: '', ...this.state.initial })

    }

    unselectHandler = event => {
        sql.remove({
            db: Config.db.name,
            table: 'selected',
            data: {
                key: 'selectedId',
                value: event.id
            }
        }).then(r => {
            sql.select({ db: Config.db.name, table: 'selected', data: 'all' })
                .then(rs => this.setState({ selected: rs }))
        })

    }

    unselectAllDestHandler = () => {
        this.state.selected.map(e => {
            if (e.type === 'dest') {
                sql.remove({
                    db: Config.db.name,
                    table: 'selected',
                    data: {
                        key: 'id',
                        value: e.id
                    }
                })
            }
        })
        sql.select({ db: Config.db.name, table: 'selected', data: 'all' }).then(r => this.setState({ selected: r }))
        this.setState({ selected: [] })

    }

    unselectExpHandler = () => {

    }

    openEditModalHandler = (item, type) => {
        this.setState({ editModal: true })
        type = type === 'dest' ? true : false;
        item.destinatar = type;
        this.setState({ currentItem: item })
        this.setState({ editModal: true })
    }

    updateModalHandlerOnClose = async (event) => {

        let exp = null, dest = null, elem = null;
        if (event === true) {
            try {
                exp = await sql.select({ db: Config.db.name, table: 'exp', data: 'all' });
                dest = await sql.select({ db: Config.db.name, table: 'dest', data: 'all' })

            } catch (err) { return err; }
            finally {
                this.setState({ exp: exp, dest: dest, initial: { dest: dest, exp: exp } });

                // update the items in selected table accordingly
                let selected = [...this.state.selected];
                selected.map((e, i) => {
                    if (e.type === 'dest') {
                        elem = dest.filter(el => el.id === e.selectedId)
                        if (elem.length > 0) {
                            selected[i].item = elem[0]
                        }
                    } else {
                        elem = exp.filter(el => el.id === e.selectedId)
                        if (elem.length > 0) {
                            selected[i].item = elem[0]
                        }
                    }

                })
                sql.freestyle(Config.db.name, 'DELETE FROM selected WHERE id NOT NULL')
                    .then(r => {
                        sql.insert({ db: Config.db.name, table: 'selected', data: selected }).then(rs => { this.setState({ selected: selected }) })
                    })
            }

        }
        this.setState({ editModal: false, inital: { exp: this.state.exp, dest: this.state.dest } })
        this.repopulate()
    }

    searchHandler = (ev) => {
        if (this.state.search === '') { this.setState({ dest: this.state.initial.dest, loading: false }) }
        this.setState({ search: ev.target.value, loading: true })
        setTimeout(() => {
            if (!this.state.initial) { this.setState({ initial: [...this.props.dest] }) }
            // let filtered = this.props.dest.filter(e => e.name.toUpperCase().indexOf(ev.target.value.toUpperCase()) !== -1)
            let filtered = sql.select(`select * from ? where name like "%${this.state.search}%"`, [this.state.dest])

            this.setState({ dest: filtered, loading: false })

        }, 1200)

    }

    linkHandler = name => event => {
        const { selected } = this.state
        const selExp = [...selected].filter(e => e.type === 'exp');
        const selDest = [...selected].filter(e => e.type === 'dest');
        this.props.history.push({
            pathname: name,
            state: { dest: selDest, exp: selExp }
        })
    }

    addContactHandler = async value => {
        console.log("value", value)
        this.setState({ addModal: false })
        if (!!value) {
            let dest = null, exp = null, sel = null;
            try {
                dest = await sql.select({ db: Config.db.name, table: 'dest', data: 'all' })
                exp = await sql.select({ db: Config.db.name, table: 'exp', data: 'all' })
                sel = await sql.select({ db: Config.db.name, table: 'selected', data: 'all' })
            } catch (err) { console.log("err", err) }
            finally {

                sel.map((e, i) => {
                    let item = [...e.type].filter(elm => elm.id === e.selectedId);
                    if (item.length > 0) {
                        sel[i].item = item[0]
                        console.log("found sel item", item[0])
                    }
                })
                console.log("sel", sel, "dest:", dest)
                this.setState({ dest: dest, exp: exp, initial: { dest: dest, exp: exp }, currentItem: null })
            }
        }
    }

    deleteHandler = (item, type) => {
        const onClose = async event => {
            if (!event) { this.setState({ msg: { open: false } }) } else {

                let r = null, s = null;
                try {
                    r = await sql.remove({ db: Config.db.name, table: type, data: { key: 'id', value: item.id } });
                    s = await sql.remove({ db: Config.db.name, table: 'selected', data: { key: 'selectedId', value: item.id } })
                } catch (err) { console.log(err) }
                finally {
                    this.repopulate();
                    this.setState({ msg: { open: false } })
                }
            }
        }
        this.setState({
            msg: {
                open: true,
                caller: 'deleteHandler',
                onClose: onClose
            }
        })
    }

    selectAllHandler = () => {
        this.setState({ selectAll: !this.state.selectAll, loading: true })
        if (this.state.selectAll === true) {
            sql.remove({ db: Config.db.name, table: 'selected', data: 'all' })
                .then(async () => {
                    let dests = [];
                    [...this.state.dest].map(e => { dests.push({ type: 'dest', selectedId: e.id, item: e }) })

                    try {

                        await sql.insert({
                            db: Config.db.name,
                            table: 'selected',
                            data: dests
                        })

                    } catch (err) { console.log(err) }
                    finally {
                        sql.select({ db: Config.db.name, table: 'selected', data: 'all' })
                            .then(r => this.setState({ selected: r, loading: false }))
                    }
                })

        } else {
            sql.remove({ db: Config.db.name, table: 'selected', data: 'all' }).then(r => this.setState({ selected: [], loading: false }))
        }

    }

    presetsShow = () => {
        this.setState({ presetsOpen: true })
    }

    presetsOnClose = dest => {
        console.log("dest:", dest)
        let { selected } = this.state
        if (dest) {
            let data = []

            dest.data.map(elem => {
                data.push({
                    selectedId: elem.id,
                    item: elem,
                    type: 'dest'
                })
            })

            console.log("closed data:", data)
            let exp = selected.filter(item => item.type = 'exp')
            this.setState({ selected: [...data, ...exp] })
        }
        this.setState({ presetsOpen: false })
    }

    resetSearch = async () => {
       return await sql.remove({db: Config.db.name, table: 'selected', data: {key: 'type', value: 'dest'}})
            .then( () => sql.select({db: Config.db.name, table: 'selected', data: 'all'})).
                then( r => this.setState({selected:r}))
    }

    removeFromDb = name => ev => {
        console.log(name, ev)
        if (ev) {
            this.deleteHandler(name, 'dest')
        }
        this.setState({removeConfirmation: false})
    } 

    render() {
        const { classes } = this.props
        const { dest, exp, selected, presetsOpen, filtered, removeConfirmation } = this.state
        const selExp = selected.filter(e => e.type === 'exp');
        const selDest = selected.filter(e => e.type === 'dest');
        const contactHandler = this.contactHandler
        return (
            <div>
                <ToolbarTop>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant = "h3" style = {{color: '#020252', fontWeight: 100}}>cores.weblogin.ro</Typography>
                        </Grid>
                        <Grid item>
                        <Button variant="contained" size="small" color="secondary" onClick={this.presetsShow} disabled={false}>
                            SETURI PREDEFINITE
                        </Button>
                        &nbsp; &nbsp;
                         <small>
                            {this.state.user
                                ? <span> {this.state.user.name}  &nbsp; ({this.state.user.email})</span>
                                : null}

                        </small>

                        <Link to={{
                            pathname: '/settings',
                            data: { ...this.state }
                        }}>
                            <Button variant="text" color="primary" size="small" >
                                <FaCog style={{ fontSize: '1.3em' }} />
                                {/* <Hidden only={'xs'}><strong>&nbsp;&nbsp;CONFIGURĂRI &nbsp;&nbsp;</strong></Hidden> */}
                            </Button>
                        </Link>
                        <Link to="/about">
                            <Button variant="text" color="primary" size="small" >
                                <FaQuestionCircle style={{ fontSize: '1.2em' }} />
                                {/* <Hidden only={'xs'}><strong>&nbsp;&nbsp;CONFIGURĂRI &nbsp;&nbsp;</strong></Hidden> */}
                            </Button>
                        </Link>
                        {/* <Button variant="text" color="primary" size="small" onClick={() => {
                            localStorage.removeItem(Config[process.env.NODE_ENV]['NAMESPACE']);
                            document.location = Config[process.env.NODE_ENV]['OAUTH_APP']
                        }}>
                            <FaPowerOff style={{ fontSize: '1.2em' }} />
                        </Button> */}
                    </Grid>
                    </Grid>
                </ToolbarTop>
                <MainContent>
                    <Grid container justify="space-around" >
                        <Grid item xs={3}>
                            <Paper className={classes.paper} style={{ height: '83vh' }}>
                                <Typography variant="caption">CONTACTE</Typography>
                                <div style = {{textAlign:'right'}}>
                                    { selected.filter( item => item.type ==='dest').length > 0 
                                    ? (
                                    <Button onClick = { this.resetSearch } >
                                    <Typography variant = "caption" color = "secondary">resetează selecția</Typography>
                                    </Button>
                                    )
                                    : <Typography variant = "caption" color = "primary">SELECȚIE DESTINATARI</Typography>
                                }

                                    
                                </div>
                                <ReactTable
                                   
                                    
                                    // filtered = {selected}
                                    style={{ height: '75vh', overflow: 'auto' }}
                                    columns={[
                                        {
                                            accessor: 'name',
                                            Cell: row => (
                                                <Typography
                                                    onClick={this.contactHandler(row.original, 'dest')}
                                                    variant="caption"
                                                    style={{ color: (selected.filter(elem => elem.selectedId === row.original.id)).length > 0 ? 'white' : 'inherit' }}
                                                >
                                                    {row.original.name} {row.original.surname}
                                                </Typography>
                                            ),
                                            width: '100%'
                                        }
                                    ]}
                                    
                                    filterable

                                    defaultFilterMethod={(filter, row) => {
                                        return row.name.toUpperCase().includes(filter.value.toUpperCase())
                                        
                                    }}
                                    pageText={(<small>Pagina </small>)}
                                    ofText={(<small> din  </small>)}
                                    rowsText="rânduri"
                                    className="-striped -highlight"
                                    previousText={<FaArrowLeft />}
                                    nextText={<FaArrowRight />}
                                    noDataText="informațiile solicitate nu există"

                                    data={dest}
                                    getTdProps={(state, row) => {

                                        return {
                                            style: {
                                                background: row && (selected.filter(elem => elem.selectedId === row.original.id)).length > 0 ? 'navy' : 'inherit'
                                            },
                                            onContextMenu: e => {e.preventDefault(); this.setState( {removeConfirmation: row && row.original ? row.original : null }) }
                                        }
                                    }}
                                />

                            </Paper>
                        </Grid>
                        <Grid item xs={9}>
                            <Grid container direction="column">
                                {/* functional toolbar */}
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                style={{ marginRight: '10px' }}
                                                // style = {{float:'right', marginRight: "90px"}} 
                                                // className="btn-default-format"
                                                onClick={() => { this.setState({ addModal: true }) }}>
                                                &nbsp;<FaPlus style={{ fontSize: '14px' }} />
                                                <Hidden only={'xs'}><a> &nbsp;&nbsp; ADAUGĂ CONTACT &nbsp;&nbsp;</a></Hidden>
                                            </Button>
                                            <Grid item>
                                                {selExp.length > 0 && selDest.length > 0
                                                    ?
                                                    <span>
                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginRight: '10px' }}
                                                            onClick={this.linkHandler('/borderou')}>
                                                            &nbsp;&nbsp;<FaEnvelopeOpen style={{ fontSize: '14px' }} />
                                                            <Hidden only={'xs'}><a > &nbsp;&nbsp; BORDEROU &nbsp;&nbsp;</a></Hidden>
                                                        </Button>

                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginRight: '10px' }}
                                                            onClick={this.linkHandler('/receipt')}>
                                                            &nbsp;<FaReplyAll style={{ fontSize: '14px' }} />
                                                            <Hidden only={'xs'}><a> &nbsp;&nbsp;CONFIRMĂRI &nbsp;&nbsp;</a></Hidden>

                                                        </Button>

                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginRight: '10px' }}
                                                            onClick={this.linkHandler('/envelope')}>
                                                            &nbsp;<FaEnvelopeOpen style={{ fontSize: '14px' }} />
                                                            <Hidden only={'xs'}><a > &nbsp;&nbsp; PLICURI &nbsp;&nbsp;</a></Hidden>
                                                        </Button>

                                                        <Button
                                                            variant="outlined"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginRight: '10px' }}
                                                            onClick={this.linkHandler('/labels')}>
                                                            &nbsp;<FaAddressCard style={{ fontSize: '14px' }} />
                                                            <Hidden only={'xs'}><a > &nbsp;&nbsp; ETICHETE &nbsp;&nbsp;</a></Hidden>
                                                        </Button>

                                                    </span>
                                                    : null
                                                }

                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Typography variant="caption"> EXPEDITOR </Typography>
                                        {
                                            exp.map((elem, index) => (
                                                <Button key={'item-' + index}
                                                    name={elem.name}
                                                    style={{ marginRight: '10px' }}
                                                    size="small"
                                                    variant={
                                                        (this.state.selected.filter(e => (e.selectedId === elem.id && e.type === 'exp'))).length > 0
                                                            ? 'contained'
                                                            : 'outlined'}
                                                    color={
                                                        (this.state.selected.filter(e => (e.selectedId === elem.id && e.type === 'exp'))).length > 0
                                                            ? 'primary'
                                                            : 'default'}
                                                    onClick={this.contactHandler(elem, 'exp')}
                                                    onContextMenu={(event) => { event.preventDefault(); this.deleteHandler(elem, 'exp') }}

                                                >
                                                    <small>{elem.name}</small>

                                                </Button>
                                            ))
                                        }

                                    </Paper>
                                </Grid>

                                {/* expeditor selectat */}
                                <Grid item xs={12} >


                                    <Paper className={classes.paper}>
                                        <div style={{ textAlign: 'left', height: '18vh' }}>
                                            <Typography variant="overline">Expeditor: </Typography>
                                            {selExp.length > 0
                                                ? <SelCustomer
                                                    {...selExp[0]}
                                                    width="260px"
                                                    confirmDialogOpen={this.state.confirmDialogOpen}
                                                    unselect={this.unselectHandler}
                                                    editHandler={value => this.openEditModalHandler(value, 'exp')}
                                                    deleteHandler={value => this.deleteHandler(value, 'exp')}

                                                />
                                                : 'neselectat'
                                            }
                                        </div>

                                    </Paper>
                                </Grid>
                                {/* destinatari selectati */}
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>

                                        <Paper className={classes.paper}>
                                            <div style={{ textAlign: 'left' }}>
                                                <Typography variant="overline">
                                                    Destinatari {selDest.length > 0 && (<span>({selDest.length})</span>)}:
                                                </Typography>
                                                <Button variant="text" size="small" color="secondary" onClick={this.unselectAllDestHandler}  >
                                                    {selDest.length > 0
                                                        ? <small><FaTimesCircle /> &nbsp;&nbsp; elimină întreaga selecție</small>
                                                        : 'neselectati'
                                                    }
                                                </Button>
                                                <Grid container style = {{height: '25vh', overflow: 'auto'}}>
                                                    {selDest.map((item, index) => (
                                                        <SelCustomer
                                                            key={`dest-${index}`}
                                                            {...item}
                                                            width="260px"
                                                            confirmDialogOpen={this.state.confirmDialogOpen}
                                                            unselect={this.unselectHandler}
                                                            editHandler={value => this.openEditModalHandler(value, 'exp')}
                                                            deleteHandler={value => this.deleteHandler(value, 'exp')}
                                                        />
                                                    ))
                                                    }
                                                </Grid>
                                            </div>
                                        </Paper>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </MainContent>
                <div id="modal-placeholder"></div>
                <AddCustomer open={this.state.addModal} onClose={this.addContactHandler} />
                <EditCustomer
                    {...this.state.currentItem}
                    open={this.state.editModal}
                    onClose={event => this.updateModalHandlerOnClose(event)}
                />
                <ConfirmDialog {...this.state.msg} />
                <ConfirmDialog open = {Boolean(removeConfirmation)} onClose = {this.removeFromDb(removeConfirmation)} />
                <Presets open = { presetsOpen } onClose =  {this.presetsOnClose} data = {{ dest: dest, selected: selDest}} />
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Customers))
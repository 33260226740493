import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { sql } from '../../../Utils'
import * as XLSX from 'xlsx';
import download from 'downloadjs'

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Config from '../../../config';

const styles = () => ({
    root: {
        minWidth: '60vh',
        width: '80%',
        margin: 'auto',
       
    },
    paper: {
        margin: '5px',
        padding: '30px'
    }

})

class ExcelExport extends React.Component {
   
    state = { data: null }

    XLS_export = () => {
        console.log("export button clicked!")
        sql.freestyle('posta', 'SELECT * FROM dest').then(
            data => {
                console.log("Data:", data)
                var ws = XLSX.utils.json_to_sheet(data);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "DESTINATARI")
                var str = XLSX.write(wb, { bookType: 'xls', type: 'binary' });

                console.log()
                let azi = new Date().toISOString().split('T')[0];
                download(str, azi + "-backup-contacte.xls", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            }
        )

    }

    urlEncoder = ( arr ) => {
        return arr.map( item => {
            let keys = Object.keys(item)
            keys.map( key => item[key] = encodeURI(item[key]))
            return item
        })
    }

    JSON_export = async () => {
        let result = {};
        let azi = new Date().toISOString().split('T')[0];

        try {
            result['dest']  = await sql.select({db: Config.db.name, table: 'dest', data: 'all'});
            result['exp']  = await sql.select({db: Config.db.name, table: 'exp', data: 'all'});
            result['config']  = await sql.select({db: Config.db.name, table: 'config', data: 'all'});
            result['selected']  = await sql.select({db: Config.db.name, table: 'selected', data: 'all'});

         } catch(err) { return err } 
        finally { 
            //url encode values for each table in result array
            const keys = Object.keys(result)
            keys.map( key => {
                if ( ['dest', 'exp'].includes(key)){
                    result[key] = this.urlEncoder(result[key])
                }
               
            })

            download(JSON.stringify(result), azi + "-backup-contacte---.json")
        }
    }

    CONFIG_export = async () => {
        let result = {};
        let azi = new Date().toISOString().split('T')[0];

        try {
            result['config']  = await sql.select({db: Config.db.name, table: 'config', data: 'all'});

         } catch(err) { return err } 
        finally { 
            console.log(result)
            download(JSON.stringify(result), azi + "-backup-setari.json", "application/json")
        }
    }    

    render() {
        const { classes } = this.props;
        return (
            <div className = {classes.root}>
                
                <Grid container direction = "column">

                    <Paper className = {classes.paper}>
                        
                        <Button 
                            variant = "outlined"
                            color = "primary"
                            size="large"
                            onClick={this.XLS_export}> 
                                EXPORTĂ UN BACK-UP AL DESTINATARILOR IN FORMAT XLSX
                        </Button>
                        
                        <Typography variant ='caption' style = {{padding: '10px'}}>
                        
                                <strong>Util pentru importul de date in masă.</strong><br/>
                                Dacă veți folosi EXACT acest model de fisier, fara a modifica numele coloanelor, aveți
                                 posibilitatea ca, ulterior, să inserați sau chiar să restaurați datele contactelor Dvs
                                  automat în aplicație prin  această procedură.
                        </Typography>
                    
                    </Paper>
                        
                    <Paper className = {classes.paper}>
                        <Button 
                            disabled = {false}
                            variant = "contained"
                            color = "primary"
                            size="large"
                            onClick={this.JSON_export}> 
                                EXPORTĂ UN BACK-UP COMPLET IN FORMAT JSON! 
                        </Button>                        
                        
                        <Typography variant ='caption' style = {{padding: '10px'}}>
                                <strong style = {{color:'red'}}>
                                    RULAȚI PERIODIC, CEL PUȚIN O DATĂ PE SĂPTĂMÂNĂ!!!
                                </strong><br/>
                                <div style = {{color: "navy", fontWeight:'800'}}>ACESTA ESTE SINGURUL MOD DE A VĂ ASIGURA CĂ DATELE DVS. SUNT ÎN SIGURANȚĂ DEPLINĂ, CHIAR ȘI ATUNCI CÂND SE STERGE ISTORICUL ȘI CACHE-UL BROSERUL-UI CHROME ACCIDENTAL!</div>
                                JSON (JavaScript Object Notation) este standardul de facto pentru datele manipulate de 
                                limbajul JavaScript, așadar și de toate browserele pentru Internet (Google Chrome, Mozilla Firefox, 
                                Internet Explorer, Microsoft Edge etc.).
                                Deși exportul și respectiv importul de date în această aplicație au fost testate cu atenție și funcționează
                                fără cusur, vă recomandăm cu căldură ca salvarea back-up-urilor periodice să o faceți în format JSON, întrucât 
                                este mult mai sigur și conține, în plus față de XLSX, TOATE setările Dvs. personale (selecții, expeditori, destinatari, configurări de tipărire etc.)  
                                <br/>

                        </Typography>    
                        </Paper>     

                    <Paper className = {classes.paper}>
                        <Button 
                            variant = "outlined"
                            color = "primary"
                            size="large"
                            onClick={this.CONFIG_export}> 
                                EXPORTĂ UN BACK-UP AL CONFIGURĂRILOR (FĂRĂ CONTACTE)
                        </Button>                        
                        
                        <Typography variant ='caption' style = {{padding: '10px'}}>
                                <strong>Păstrați în siguranță setările personalizare de imprimare ale plicurilor</strong><br/>
                                    Întucât practica ne-a demonstrat că la tipărirea dintr-o aplicație web rar găsim două imprimante care să se comporte absolut identic 
                                    în ceea ce privește marginile paginii, vă oferim posibilitatea de a face o copie (într-un fișier backup) a setărilor personalizate de 
                                    Dvs. de-a lungul timpului. <br/> Veți putea ulterior să restaurați aceste setări în siguranță, fără a afecta sub nici o formă contactele
                                    existente în baza locală de date.
                        </Typography>    
                        </Paper>                                  
                    </Grid>

            </div>
        )
    }
}

export default withStyles(styles)(ExcelExport);
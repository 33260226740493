import React from 'react';
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core'
import './Receipt.css';
import ToolbarTop from '../Layout/ToolbarTop/ToolbarTop';
import MainContent from '../Layout/MainContent/MainContent';

const styles = theme => ({
    root: { flexGrow: 1 }
})

class Receipt extends React.Component {

    linkHandler = name => event => {
        this.props.history.push({
            pathname: name,
            state: {dest: this.props.location.state.dest, exp: this.props.location.state.exp}
        })
    }
     printHandler = () => {
         window.print()
     }

    render() {
        const { classes } = this.props; 

        console.log("CONFIMRARI PROPS:",this.props)
        return (
            <div className = { classes.root }>
            <ToolbarTop className = "no-print">
                 <div>
                        <button 
                            className = "btn-default-format"
                            style = {{float:'right'}}
                            onClick = {this.linkHandler('/borderou')}
                            >BORDEROU
                        </button>
                        <button 
                            className = "btn-default-format"
                            style = {{float:'right'}}
                            onClick = {this.linkHandler('/envelope')}
                            >PLICURI
                        </button>

                        <button 
                            className = "btn-default-format"
                            style = {{float:'right'}}
                            onClick = {this.linkHandler('/')}
                            >HOME
                        </button>  
                    
                    </div> 
                    <button 
                        className = "btn-default-format"
                        style = {{float:'right'}}
                        onClick = {this.printHandler}
                        >PRINT
                    </button> 
                                                       
                </ToolbarTop> 
                <MainContent className = "portrait">
                    <div>
                {this.props.location.state.dest.map( (e,i) => (
                    
                    <div key = {i} className = "container">
                    {/* destinarar */}
                    <strong style = {{margin:'205px 0 0 120px', position: 'absolute'}}>{e.item.name}</strong>
                    <strong style = {{margin:'245px 0 0 120px', position: 'absolute'}}>{e.item.str}</strong>
                    <strong style = {{margin:'245px 0 0 450px', position: 'absolute'}}>{e.item.nr}</strong>
                    <strong style = {{margin:'275px 0 0 95px', position: 'absolute'}}>{e.item.bl ? e.item.bl : '---'}</strong>
                    <strong style = {{margin:'275px 0 0 165px', position: 'absolute'}}>{e.item.et ? e.item.et : '---'}</strong>
                    <strong style = {{margin:'275px 0 0 235px', position: 'absolute'}}>{e.item.sc ? e.item.sc : '---'}</strong>
                    <strong style = {{margin:'275px 0 0 300px', position: 'absolute'}}>{e.item.ap ? e.item.ap : '---'}</strong>
                    <strong style = {{margin:'275px 0 0 410px', position: 'absolute'}}>{e.item.cp ? e.item.cp : '---'}</strong>
                    <strong style = {{margin:'305px 0 0 105px', position: 'absolute'}}>{e.item.loc ? e.item.loc : '---'}</strong>
                    <strong style = {{margin:'305px 0 0 255px', position: 'absolute'}}>{e.item.com ? e.item.com : '---'}</strong>
                    <strong style = {{margin:'305px 0 0 395px', position: 'absolute'}}>{e.item.jud ? e.item.jud : '---'}</strong>
                    
                    {/* expeditor */}
                    {/* <pre>{JSON.stringify(this.props.location.state.exp,null,4)}</pre> */}
                    <strong style = {{margin:'200px 0 0 550px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.name}
                    </strong>
                    <strong style = {{margin:'245px 0 0 560px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.str ? this.props.location.state.exp[0].item. str : '---'}
                    </strong>    
                    <strong style = {{margin:'245px 0 0 915px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.nr ? this.props.location.state.exp[0].item.nr : '---'}
                    </strong>  
                    <strong style = {{margin:'275px 0 0 550px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.bl ? this.props.location.state.exp[0].item.bl : '---'}
                    </strong>  
                    <strong style = {{margin:'275px 0 0 620px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.et ? this.props.location.state.exp[0].item.et : '---'}
                    </strong>    
                    <strong style = {{margin:'275px 0 0 685px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.sc ? this.props.location.state.exp[0].item.sc : '---'}
                    </strong>     
                    <strong style = {{margin:'275px 0 0 750px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.ap ? this.props.location.state.exp[0].item.ap : '---'}
                    </strong>   
                    <strong style = {{margin:'275px 0 0 860px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.cp ? this.props.location.state.exp[0].item.cp : '---'}
                    </strong>   
                    <strong style = {{margin:'300px 0 0 560px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.loc ? this.props.location.state.exp[0].item.loc : '---'}
                    </strong>  
                    <strong style = {{margin:'300px 0 0 700px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.com ? this.props.location.state.exp[0].item.com : '---'}
                        
                    </strong>  
                    <strong style = {{margin:'300px 0 0 870px', position: 'absolute'}}>
                        {this.props.location.state.exp[0].item.jud ? this.props.location.state.exp[0].item.jud : '---'}
                        
                    </strong>  
                    {  ((i+1) % 2 === 0) ? <footer/> : null }
                    </div>
                    
                ))}
              </div>
            </MainContent>
            </div>            
        )
    }
}

export default withRouter(withStyles(styles)(Receipt));
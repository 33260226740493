import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom';
import Config from '../../config';
import { Axios } from '../../Utils'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from '../../assets/img/logo.png';

const styles = theme => ({
    root: {
        flexGrow:1
    },
    progress: {
      margin: theme.spacing.unit * 2
    },
    paper: {
        padding: theme.spacing.unit,
    }
  });

class Login extends React.Component {

    state = { token: null, dataLoaded: false, warning: null };

    async componentDidMount() {
        let result = null;
        const token = this.props.match.params.token
        console.log("mounted", this.props.match.params.token)
        if ( token === 'not-logged-in' || token === null ) {
            this.setState( {warning: 'not-auth'})
        } else {
            try {
                result = await Axios({
                    method: 'POST',
                    url:'/is-token-valid',
                    headers: {['x-auth-token']: token }
                }) 
            } catch(err) { console.log("token check error: ", err)}
            finally {
                console.log("login result:", result)
                if ( !(result && result.data && result.data.id) ) {this.setState({warning: 'forbidden'})} 
                else {
                   
                    localStorage.setItem(Config[process.env.NODE_ENV]['NAMESPACE'], JSON.stringify({...result.data, token:token}))
                    this.props.history.push('/')
                }
            }

        }

    }

    render() {

        const { classes } = this.props;
        return (
           
                    <Grid container  direction = "row" justify = "center" alignItems="center" className = {classes.root} style = {{height:'100vh'}}>
                        
                        <Grid item xs = {6}>
                        <Paper className = {classes.paper}> 
                            {!this.state.warning 
                            ? <div>
                                <CircularProgress />Verificăm cheia Dvs de licență...
                            </div>
                            : <div>
                                { this.state.warning ==='not-auth' 
                                    ? (
                                        <div  style = {{textAlign:'center'}}>
                                            <img src = {Logo}/>
                                        <Typography variant = "caption" color = "primary" style ={{textAlign:"center"}}>
                                        Ne pare rău, acest serviciu web este disponibil exclusiv clienților firmelor 
                                        <br/> 
                                        </Typography>
                                        <Typography variant = "caption" color = "primary" style ={{textAlign:"center"}}>
                                            <strong>SIGN Portal srl și Maftei Gabriel-Claudiu pfa</strong>
                                        </Typography>
                                        <br/>
                                        <Typography variant = "caption" color = "primary" style ={{textAlign:"center"}}>
                                        Dacă doriți testarea serviciului nostru, puteți accesa o versiune demonstrativă 
                                        <br/>(cu funcționalități limitate), autentificându-vă cu 
                                        <br/>nume de utilizator <strong style = {{color:'red'}}>cores</strong> și parola <strong style = {{color:'red'}}>cores</strong>
                                         </Typography>
                                         <hr/>
                                        <div style = {{textAlign:'center'}}>
                                        <Button color = "primary" variant = "outlined" size = "small" href = { Config[process.env.NODE_ENV]['OAUTH_APP']}>AUTENTIFICARE</Button>
                                        </div>
                                        </div>
                                    ): null
                                }
                                { this.state.warning ==='forbidden' 
                                    ? (
                                        <div  style = {{textAlign:'center'}}>
                                            <img src = {Logo}/>
                                            <Typography variant = "caption">
                                            Cheia Dvs. de acces nu a putut fi verificată. Probabil abonamentul Dvs. a expirat și accesul Dvs. în aplicație a fost restricționat.
                                            <hr/>
                                            <br/> Încercați o &nbsp;&nbsp;
                                            <Button color = "secondary"  variant = "outlined" size = "small" href = { Config[process.env.NODE_ENV]['OAUTH_APP']}>reautentificare</Button>
                                        
                                            &nbsp;&nbsp; sau contactați furnizorul acestui serviciu web.
                                            </Typography>
                                        </div>
                                        
                                    ): null
                                }
                            </div>
                            }
                        </Paper>
                        </Grid>
                       
                    </Grid>

        )
    }
}

export default withRouter(withStyles(styles)(Login));

import React from 'react';
import { withRouter } from 'react-router-dom';

import './Envelope.css';
import AddressLabel from './AddressLabel';
import Config from '../../config';
import ToolbarTop from '../Layout/ToolbarTop/ToolbarTop';
import { sql } from '../../Utils';
import Grid from '@material-ui/core/Grid';
import { FaCog, FaEnvelope } from 'react-icons/fa';
import { Paper, Typography } from '@material-ui/core';

class Envelope extends React.Component {

    state = {
        config: null,
        selEnvelope: null,
        printerType: 'laser'
    }

    componentDidMount() {
        sql.select({ db: Config.db.name, table: 'config', data: 'all' }).then(
            r => {
                // console.log(r)
                this.setState({ config: r });
                this.setState({ selEnvelope: r.filter(e => e.default === true)[0] })
            }
        )
    }

    getFlex = (printerType) => {
        let flexType
        switch(printerType) {
            case 'laser' : flexType = "center"; break;
            case 'inkjet1' : flexType = "flex-start"; break;
            case 'inkjet2' : flexType = "flex-end"; break;
            default : flexType = 'center'
        }
        return flexType
    }

    setEnvelopeHandler = (event) => () => {
        console.log("event", event);

        this.setState({ selEnvelope: event })
        let config = [...this.state.config];
        config.map((e, i) => {
            config[i].default = e.id === event.id ? true : false
        })
        this.setState({ config: config })

    }

    printHandler = () => {
        window.print();
    }

    linkHandler = name => event => {
        this.props.history.push({
            pathname: name,
            state: { dest: this.props.location.state.dest, exp: this.props.location.state.exp }
        })
    }

    render() {

        const { printerType, config } = this.state;

        // console.log("PLICURI PROPS:", this.props)
        return (
            <div>

                <div className="no-print" >
                    <ToolbarTop>

                        <div>
                            {this.state.selEnvelope
                                ? <button
                                    className="btn-default-format"
                                    style={{ float: 'right' }}
                                    onClick={this.linkHandler('/settings/styles/' + this.state.selEnvelope.format)}>
                                    <FaCog style={{ fontSize: '1em' }} />&nbsp; <FaEnvelope />
                                </button>
                                : null
                            }

                            <button
                                className="btn-default-format"
                                style={{ float: 'right' }}
                                onClick={this.linkHandler('/borderou')}
                            >BORDEROU
                        </button>
                            <button
                                className="btn-default-format"
                                style={{ float: 'right' }}
                                onClick={this.linkHandler('/receipt')}
                            >CONFIRMARI
                        </button>

                            <button
                                className="btn-default-format"
                                style={{ float: 'right' }}
                                onClick={this.linkHandler('/')}
                            >HOME
                        </button>

                        </div>
                        <div>
                            <button
                                className="btn-default-format"
                                style={{ width: '100px' }}
                                onClick={this.printHandler}
                            >PRINT
                        </button>
                        </div>

                        <div>
                            {this.state.config ? this.state.config.map((e, i) => (
                                <span key={"format-" + i}>

                                    <button
                                        className={e.default === true ? 'btn-selected-format' : 'btn-default-format'}
                                        onClick={this.setEnvelopeHandler(e)}>{e.format}
                                    </button>
                                </span>
                            )) : null}
                        </div>

                    </ToolbarTop>
                </div>
                {
                    this.state.selEnvelope ? (
                        <div className="" >

                            {this.props.location.state.dest.map((e, i) => (
                                <Grid 
                                    container key={"dst"+i}  
                                    direction="column" 
                                    justify= {this.getFlex(this.state.selEnvelope.printerType ? this.state.selEnvelope.printerType.val : 'laser')} 
                                    alignItems="flex-end" 
                                    className="landscape" 
                                >
                                    <Grid item></Grid>
                                    <Grid item>
                                        <div className="iso-envelope"
                                            style={{ ...this.state.selEnvelope }}>
                                            {/* expeditor */}
                                            {!!this.state.selEnvelope.exp
                                                ?
                                                (<div style={{ position: 'relative', ...this.state.selEnvelope.exp }}>
                                                    <AddressLabel
                                                        type="exp"
                                                        box={this.state.selEnvelope.box}
                                                        {...this.props.location.state.exp[0].item}
                                                    />
                                                </div>)
                                                : null}

                                            {/* destinatar */}
                                            {!!this.state.selEnvelope.dest
                                                ? (<div style={{ position: 'relative', ...this.state.selEnvelope.dest }}
                                                    box={this.state.selEnvelope.box}>
                                                    <AddressLabel
                                                        type="dest"
                                                        box={this.state.selEnvelope.box}
                                                        {...e.item} />
                                                </div>) : null}
                                            <footer></footer>
                                        </div>
                                    </Grid>
                                </Grid>
                                ))
                            }

                        </div>
                    ) : <Paper style = {{padding:'10%'}}> 
                            <Typography variant ="body1">
                                Pentru a continua, alege un format preconfigurat de plic din partea dreaptă a barei de meniu (sus) <br/>
                                Dacă folosești în mod frecvent un anumit model, setează-l ca implicit din Secțiunea  
                                <strong><a href = "settings/styles"> Setări / Stiluri </a></strong>. <br/><br/>
                                Când există setat un model implicit acest mesaj nu va mai fi afișat.
                            </Typography>
                        </Paper>
                }

            </div>

        )
    }
}

export default withRouter(Envelope);
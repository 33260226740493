import React from 'react';
import './SelCustomer.css';
import Grid from "@material-ui/core/Grid"
import { FaTimesCircle } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';

const SelCustomer = (props) => {

    return(
        <div>
        { props.item 
            ?
            <Grid  className = "customer-box" style = {{width:props.width}}>

            <Grid container>
                <Grid item className = "customer-title" xs = {11}>
                    {props.item.name}
                </Grid>
                <Grid item xs={1} style ={{textAlign:'right'}} value ={props} onClick = {() => props.unselect(props.item)}>
                        <Tooltip title = "elimină din selecție"><FaTimesCircle style ={{color:'lightGrey'}} /></Tooltip>
                </Grid>
                <Grid item className = "customer-content" xs = {12}>

                     {props.item.str ? 'Str. '+ props.item.str + ' ': null}
                     {props.item.nr ? 'Nr. '+ props.item.nr + ' ': null}
                     {props.item.bl ? 'Bl. '+ props.item.bl + ' ': null}
                     {props.item.sc ? 'Sc. '+ props.item.sc + ' ': null}
                     {props.item.et ? 'Et. '+ props.item.et + ' ': null}
                     {props.item.ap ? 'Ap. '+ props.item.ap + ' ': null}
                     <br/>
                     {props.item.loc ? 'Loc. '+ props.item.loc + ' ': null}
                     {props.item.com ? 'Com. '+ props.item.com + ' ': null}
                     {props.item.jud ? 'Jud. '+ props.item.jud + ' ': null}
                     {props.item.cp ? 'Cod poștal. '+ props.item.cp + ' ': null}
                     <br/>
                </Grid>
                <Grid item className = "customer-actions" style = {{textAlign:'right'}} xs={12}>
                    <Tooltip title = "EDITEAZĂ ACEST CONTACT">
                    <a  onClick = {() => props.editHandler(props.item)}>Modifică</a>
                    </Tooltip>

                    &nbsp;&nbsp;&nbsp;
                    <Tooltip title = "STERGE ACEST CONTACT DIN BAZA DE DATE">
                    <a  onClick = {() => props.deleteHandler(props.item)}>Șterge</a>
                    </Tooltip>

                    &nbsp;&nbsp;
                </Grid>
            </Grid>
            
        </Grid>
        : <div></div>}
       </div>
        
        )
    
}

export default SelCustomer;
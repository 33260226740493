/* eslint-disable */

import alasql from 'alasql';
// import React from 'react';

export default class Sql  {
    // props
    //      db: string
    //      table: string
    //      query: string || object    
    constructor(props){
        this.props = props;
        
    }

    static timestamp = () =>  new Date().getTime()

    static uuid() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
      }

    static createDB(props) {
        
        alasql( `CREATE localstorage DATABASE IF NOT EXISTS ${props.db}` );
        alasql(`ATTACH localStorage DATABASE ${props.db}` );
        return true
        
    }

    static async createTable(props) {
        let promises = []
        promises.push(await alasql.promise(`ATTACH localStorage DATABASE ${props.db}` ));
        promises.push(await alasql.promise(`USE DATABASE ${props.db}` ))
        promises.push(await alasql.promise(`CREATE TABLE IF NOT EXISTS ${props.table}  ${props.data}` ))
        // alasql(`CREATE INDEX id ON ${props.table}(id)`);
        return  Promise.all(promises).then( r => {
            console.log("tables structures have been created or updated! ***********************"); 
            return true}
        )
    }



    static async insert(props) {
        props.data = (!Array.isArray(props.data)) 
            ? [props.data]
            : props.data;
            alasql(`ATTACH localStorage DATABASE ${props.db}` );
            alasql(`USE DATABASE ${props.db}` )
            props.data.map( (e,i) => props.data[i].id = this.uuid())
        return  alasql.promise('INSERT INTO '+props.table+' SELECT * FROM ?',[props.data]);
        // return  alasql.promise(`INSERT INTO ${props.table} SELECT * FROM ?`, props.data[0]);
            
    }

    static async insertGetId(props) {
        props.data =  [props.data]
        let result = null;
        try {
            alasql(`ATTACH localStorage DATABASE ${props.db}` );
            alasql(`USE DATABASE ${props.db}` )
            props.data[0].id = this.uuid()
            result = await alasql.promise('INSERT INTO '+props.table+' SELECT * FROM ?',[props.data]).then( () => props.data[0].id )
        } catch(err) { return err }
        finally {
            return props.data[0].id;
        }

    }

    static update(props) {
        alasql(`ATTACH localStorage DATABASE ${props.db}` );
        alasql(`USE DATABASE ${props.db}` )
        let keys = Object.keys(props.data)
        let set = ''
        keys.map( (e,i) => {
            if ( e !== 'id' ) {
                set += `${e} = \'${props.data[e]}\'`
                set += i !== keys.length-1 ? ', ':'';
            }
            console.log("QUERY:",`UPDATE ${props.table} SET ${set} WHERE id = ` + '"'+ props.data.id + '"')
       })
       return alasql.promise(`UPDATE ${props.table} SET ${set} WHERE id = ` + '"'+ props.data.id + '"');
    }
    
     static select(props) {
        let q = '';
        // console.log(props);
            alasql(`ATTACH localStorage DATABASE ${props.db}` );
            alasql(`USE DATABASE  ${props.db}` )
            if ( props.data === 'all' ) {
                q = `SELECT * FROM ${props.table}`
            } else {
                if ( props.data.key ) {
                    q = `SELECT * FROM ${props.table} WHERE ${props.data.key} = `+ '"'+props.data.value+'"'
                } else {
                    q = 'SELECT * FROM '+ props.table +' WHERE id = "' + props.data + '"';
                }
                
            }
        return alasql.promise(q)
    }

    static remove(props) {
        alasql(`ATTACH localStorage DATABASE ${props.db}` );
        alasql(`USE DATABASE  ${props.db}` )
        // console.log(`DELETE FROM ${props.table} WHERE ${props.data.key} = `+ '"'+props.data.value+'"');
        let q = null;
        if ( props.data === 'all') {
            q = `DELETE FROM ${props.table} WHERE id NOT NULL`;      
        } else {
            q = `DELETE FROM ${props.table} WHERE ${props.data.key} = `+ '"'+props.data.value+'"'
        }
        return alasql.promise(q)        
    }



    static freestyle(db,props) {
        alasql(`ATTACH localStorage DATABASE ${db}` );
        alasql(`USE DATABASE  ${db}` );
        return alasql.promise(props);
    }
}

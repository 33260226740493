import React, { Component } from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, Paper, Button, withStyles, TextField, Chip, Fab, List, ListItem, Typography, Divider } from '@material-ui/core'
import { FaCheck, FaPlus } from 'react-icons/fa'
import ReactSelect from 'react-select'
import { sql } from '../../Utils';
import Config from '../../config';

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing.unit,
        margin: theme.spacing.unit,
    },
    lg:  { width: '100vw', marginTop:'10px', marginBottom: '10px', zIndex: 9999999},
    chip: {
        margin: theme.spacing.unit*0.2,
        
    }
    

})
class Presets extends Component {

    state = {
        open: false,
        dest: null, 
        presets: [],
        initPresets: null,
        activePreset: null,
        currentSet: null,
        selectedItem: null
    }

    getSets = async () => {
        let promise = null
        try {
            promise = await sql.select({ db: Config.db.name, table: 'preset', data: 'all' })
        } catch(err) { console.log("presets retrival error:", err); promise = []}
        finally {
            // console.log("prmise:", promise)
            
            this.setState({presets: promise})
            return promise
        }
    }

    async componentDidMount() {
        let promise = null
        try {
            promise = await this.getSets()
        } catch(err) { console.log("presets select error:", err)}
        finally {
            this.setState({initPresets: promise})
        }
    }
    async componentDidUpdate() {
        if ( this.props.open !== this.state.open ) {
            let dest = this.props.data.dest
            dest.map( elem => {
                elem.label = elem.name
                elem.value = elem.id
                return elem
            })
            this.setState({
                open:this.props.open,
                dest: dest,
                presets: await this.getSets() || []
            })
        }
    }



    onClose = data => async () => {
        let promise = null
        let { presets, initPresets } = this.state
        if ( JSON.stringify(presets) !== JSON.stringify(initPresets) && presets.length > 0 ) {
            // console.log("removing...", initPresets)
            return await sql.remove({db: Config.db.name, table:'preset', data: 'all'})
                .then( () => {
                    // console.log("inserting...", presets)
                    sql.insert({db: Config.db.name, table: 'preset', data: presets })
                        .then( (r) => {
                            console.log("close preset:",data)
                            this.props.onClose(data)
                        })
                })
            
        } else {
            this.props.onClose(data)
        }
    }

    confirmSave = async () => {
        const { presets, initPresets } = this.state
        return await sql.remove({db: Config.db.name, table:'preset', data: 'all'})
        .then( () => {
            // console.log("inserting...", presets)
            sql.insert({db: Config.db.name, table: 'preset', data: presets })
                .then( (r) => {
                    alert("modificarile au fost salvate!")
                })
        })
    }

    changeName = ev => {
        let { currentSet } = this.state
        currentSet.name = ev.target.value
        this.setState({currentSet: currentSet})
    }

    createNewSetFromCurrentSelection = () => {
        let selected = []
        let { presets } = this.state
        this.props.data.selected.map( item => selected.push(item.item))
        // console.log("selected:", selected)
        let set = {name: 'set curent', data: selected}
        this.setState({ currentSet: set })
    }

    random = () => {
        return Math.random().toString(36).slice(3,10);
    }
    createNewSet = () => {
        this.setState({currentSet: {name: `set-nou-${this.random()}`, data: []}})
    }

    presetRemove = elem =>  () => {
        let { presets } = this.state
        presets.splice(presets.indexOf(elem),1)
        this.setState({presets: presets, currentSet: null})
    }
    presetSelect = elem =>  () => {
        this.setState({currentSet: elem})
    }

    removeFromCurrentSet = index => () => {
        let { currentSet } = this.state
        currentSet.data.splice(index,1)
        this.setState({currentSet: currentSet})
    }

    addItemToSet  = () => {
        let { currentSet, selectedItem } = this.state
        currentSet.data.push(selectedItem)
        this.setState({currentSet:currentSet,  selectedItem: null})
    }

    confirmSet = () => {
        let { presets, currentSet } = this.state
        if ( ([...presets].filter( item => item.name === currentSet.name)).length < 1 ) {
            presets.push(currentSet)
        } else {
            let cur = [...presets].filter( item => item.name === currentSet.name )[0]
            presets[presets.indexOf(cur)] = currentSet
        }
        
        this.setState({presets:presets, currentSet: null})
    }
    render() {
        const { open, dest, currentSet, selectedItem, presets, initPresets } = this.state
        const { classes } = this.props
        // console.log("STATE: ", this.state)
        return (
            <div>   
                <Dialog 
                    open = { open }
                    onClose = { this.onClose() }
                    maxWidth = "lg"
                    classes = {{  paperWidthLg: classes.lg }}
                >
                    <DialogTitle> SETURI PREDEFINITE DE DESTINATARI </DialogTitle>
                    <DialogContent>

                        <Grid container alignItems = "stretch" justify = "space-between" spacing = {8}>
                            <Grid item xs = {3}>
                                <Paper className = { classes.paper } style = {{height: '60vh'}}>

                                        <Button variant = "outlined" fullWidth  onClick = { this.createNewSetFromCurrentSelection } style = {{marginBottom:'5px'}}>
                                            <Typography variant = "caption">CREAZĂ SET NOU DIN SELECȚIA CURENTĂ</Typography>
                                        </Button>
                                        
                                        <Button variant = "outlined" fullWidth  onClick = { this.createNewSet } style = {{marginBottom:'15px'}}>
                                            <Typography variant = "caption">CREAZĂ SET NOU GOL</Typography>
                                        </Button>
                                        <Divider/>
                                        <Typography variant = "overline" style = {{textAlign:'center'}}>SETURI PREDEFINITE</Typography>
                                        <List style = {{height: '30vh', overflow: 'auto'}}>
                                            <Divider />
                                            { presets && presets.map( (elem, index) => (
                                                <div key = {`list-item-${index}`}>
                                                    <ListItem  onClick = {this.presetSelect(elem)}>
                                                        <Grid container justify = "space-between">
                                                            <Grid item> <span style = {{color: 'navy', fontWeight:800}}> {elem.name} </span></Grid>

                                                                <Typography
                                                                    variant = "caption" 
                                                                    style = {{}} 
                                                                    onClick = {this.presetRemove(elem)}> 
                                                                    [șterge]
                                                                </Typography>

                                                        </Grid>
                                                       
                                                    </ListItem>
                                                    <Divider />
                                                   
                                                </div>
                                            ))}
                                        </List>
                                </Paper>
                            </Grid>
                            <Grid item xs = {9}>
                                    <Paper className = { classes.paper }  style = {{height: '60vh'}}>
                                    { currentSet && (
                                        <Paper className = {classes.paper}>
                                            <Grid container alignItems = "center" justify = "flex-start">
                                                <Grid item xs = {11} > 
                                                    <TextField 
                                                        variant = "outlined" 
                                                        fullWidth = {true} 
                                                        dense = "true" 
                                                        label = "DENUMIRE SET" 
                                                        value = {currentSet.name}
                                                        onChange = { this.changeName }
                                                        />
                                                </Grid>
                                                <Grid item xs = {1} style = {{textAlign:'right'}}>
                                                    <Fab 
                                                        variant = "round" 
                                                        color = "secondary"
                                                        onClick = { this.confirmSet }
                                                    >
                                                        <FaCheck style = {{fontSize: '1.3em'}}/>
                                                    </Fab>
                                                </Grid>
                                                <Grid item xs = {12}>
                                                    <Paper className = {classes.paper}>
                                                        <Grid item xs = {12} style = {{display:'flex'}}>
                                                            <div style = {{width:'95%'}}>
                                                                <ReactSelect 
                                                                    options = {dest} 
                                                                    onChange = {ev => this.setState({selectedItem: ev}) }
                                                                    placeholder = "adaugă la set..."
                                                                    value = { selectedItem }
                                                                />
                                                            </div>
                                                            &nbsp;
                                                    
                                                            <Fab 
                                                                variant = "round"  
                                                                size = "small" 
                                                                color = "primary" 
                                                                disabled = {!this.state.selectedItem}
                                                                onClick = { this.addItemToSet }
                                                            > 
                                                                <FaPlus style = {{fontSize: '1.4em'}}/>
                                                            </Fab>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs = {12}>
                                                    <Paper className = {classes.paper}  style = {{height: '32vh', overflow: 'auto'}}>
                                                        {currentSet.data && currentSet.data.length > 0 
                                                        ?  currentSet.data.map( (item, index) =>  (
                                                            <div key = {`chip-${index}`} >
                                                            <Chip 
                                                                
                                                                label = {item.name} 
                                                                variant = "outlined"
                                                                onDelete = { this.removeFromCurrentSet(index)}
                                                                className = { classes.chip }
                                                                
                                                            />
                                                            <br/>
                                                            </div>
                                                        ))
                                                        : (<div>nu ati selectat nici un destinatar</div>)
                                                    }
                                                    </Paper>
                                                </Grid>
                                            
                                            </Grid>
                                        </Paper>
                                    )}
                                </Paper>
                
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button variant = "contained" color = "inherit" size = "small" onClick = {this.onClose() }> RENUNȚĂ </Button>
                        <Button variant = "contained" color = "secondary" size = "small" 
                            disabled = { JSON.stringify(presets) === JSON.stringify(initPresets) }
                            onClick = {this.confirmSave }> 
                            SALVEAZĂ MODIFICĂRILE 
                        </Button>
                        <Button 
                            variant = "contained" color = "primary" size = "small" 
                            disabled = { !currentSet }
                            onClick = {this.onClose(currentSet) }>  
                            APLICĂ SETUL CURENT 
                        </Button>
                    </DialogActions>
                        
                </Dialog>
            </div>
        )
    }
}

export default withStyles(styles)(Presets)
const Unit = (val) => {
    let result = { val: val, unit: null }
    const sizes = ["mm", "px", "pt", "em"];
    sizes.map(sizeUnit => {
        if (val.indexOf(sizeUnit) > 0) {
            // console.log(val, sizeUnit)
            result.unit = sizeUnit;
            result.val = parseFloat(val.replace(sizeUnit, ''))
        }
    })
    return result
}

const  Restructure = (obj) => {
    // console.log(typeof obj)
    const ExceptedKeys = ["fontWeight", "border", "default",  "background", "id", "color", "printerType" ]
    if (typeof obj === 'object') {
       
            obj = {...obj}
            let keys = Object.keys(obj);
            keys.map( key => {
                // console.log("key:", key)
                switch(true) {

                    case (ExceptedKeys.indexOf(key) >= 0 ) :
                        // console.log("excepted key : ", key)
                        break;
                    case (typeof obj[key] === 'boolean'):
                        break;
                    case (typeof obj[key] === 'number'):
                        break;                        
                    case obj[key].hasOwnProperty('val') && obj[key].hasOwnProperty('unit') :
                        let unit =  ( obj[key] && obj[key]['unit'] ) ?  obj[key]['unit'] : '';
                        let val  =  ( obj[key] && obj[key]['val']  ) ?  obj[key]['val']  : '';
                        obj[key] = ''+val+unit;
                        break;
                    default:
                        obj[key] = Restructure(obj[key]);
                        break;
                }
                // if ( typeof obj[key] === 'object' && !obj[key]['val']) {
                //     obj[key] = Restructure(obj[key]);
                // }
                // else{
                //     if (obj[key]['val']) { 
                //         let unit =  ( obj[key] && obj[key]['unit'] ) ?  obj[key]['unit'] : '' 
                //         let val  =  ( obj[key] && obj[key]['val']  ) ?  obj[key]['val']  : ''
                        
                //         obj[key] = ''+val+unit;
                //         if ( key in ExceptedKeys ) {
                //             obj[key] = obj[key];
                //         }
                //         delete(obj[key]['unit']);
                //         delete(obj[key]['val']);
                //     } else {
                //         setTimeout( ()=>obj[key]=Restructure(obj[key]))
                //     }
                // }

            })
            
    }
    console.log("obj:", obj)

	return obj
}

export default Restructure;
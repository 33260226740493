import React from 'react';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import { sql, Hide } from '../../../Utils';
import Config from '../../../config';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography';
import EnvelopePreview from '../SetStyles/EnvelopePreview';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { FaPlus } from 'react-icons/fa';
import { TextField } from '@material-ui/core';

class SetStyles extends React.Component {

    state = {
        data: null,
        dataLoaded: null,
        choosenItem: null,
        newItem: {format: ''}

    }
    
    async componentDidMount() {
        let result = null
        try {
            result = await sql.select({ db: Config.db.name, table: 'config', data: 'all' })
        } catch (err) { return err; }
        finally {
            this.setState({
                data: result,
                dataLoaded: true,
                params: this.props.match.params.format || null
            })
            if (this.props.match.params.format) {
                this.setState({ choosenItem: (result.filter(elem => elem.format.toUpperCase() === this.props.match.params.format.toUpperCase()))[0] })
            }
        }
    }

    componentWillReceiveProps(newProps) {
        const { data } = this.state
        let envelopeFormat = newProps.location.pathname.split('/')
        envelopeFormat = envelopeFormat.length === 4 ? envelopeFormat[3] : null
        if (envelopeFormat) {
            this.setState({
                choosenItem: data.filter( elem => elem.format.toUpperCase() === envelopeFormat )[0]
            })
        }
    }

    resetHandler = () => {
        this.setState({ currentItem: null })
        console.log("rest")
    }

    removeFormatHandler = name => event =>{
        event.preventDefault()
        console.log("context:",name, this.state.data.indexOf(name))
        let confirm = window.confirm("CHIAR DORITI STERGEREA?", "DA", "NU")
        if ( confirm === true ) {
            sql.remove({db:Config.db.name, table: 'config', data:{key:'id', value: name.id }})
            .then( resp => {
                let data = [...this.state.data]
                data.splice(data.indexOf(name),1)
                this.setState({data:data})
                this.props.history.push('/settings/styles');
            })
        }

    }

    newItemInitHandler = () => {
        let data = this.state.data;
        let newRandomName = 'new-'+Math.floor(Math.random() * 90000) + 10000
        let newItem = {...data[0]}
        newItem.format = newRandomName;
        delete(newItem.id)
        console.log("newItem", newItem)
        sql.insertGetId({db: Config.db.name, table: 'config', data: newItem})
        .then( r => {
            newItem.id = r;
            data.push(newItem)
            this.setState({data:data, newItem: newItem})
        })
    }

    newNameHandler = event => {
        let data = this.state.data;
        data[data.length-1].format = event.target.value;
        console.log("data after name update:", data)
        sql.update({db: Config.db.name, table: 'config', data: event.target.value })
            .then( () => {
                this.setState({data: data})
            })
    }


    render() {
        const { currentItem } = this.state
        // console.log("STYLES state------>", this.state);
        // console.log("STYLES props------>", this.state);
        return (
            <div>
                {this.state.dataLoaded === true
                    ? (<div>
                        <Paper style={{ padding: '10px', margin: '5px' }}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="stretch" >
                            
                                <Grid item>
                                    <Typography variant="caption">Alegeți modelul pe care doriți să-l editați:</Typography>

                                    <Grid container direction = "row" justify = "flex-start" alignItems= "center">
                                        <Grid item>
                                            {this.state.data.map((e, i) => (
                                                <Link to = {`${this.props.match.url}/${e.format}`}   key={'frmt-' + i} >
                                                <Button 
                                                    onContextMenu = {this.removeFormatHandler(e) }
                                                    variant = { this.props.location.pathname  === `${this.props.match.url}/${e.format}` ? 'outlined' : 'text'} >
                                                    {e.format}
                                                </Button>
                                                </Link>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item>
                                    <Grid container direction = "row" justify = "flex-start" alignItems= "stretch">
                                        <Grid item>
                                            {/* {this.state.newItem.format !== ''
                                             ?   <TextField variant = "outlined" margin ="none"  size="small" label = "Numele noului model" 
                                                value = {this.state.data[this.state.data.length-1].format}
                                                onChange = { this.newNameHandler }
                                                />
                                             : null } */}
                                            &nbsp;
                                            <Fab 
                                                variant = "round" 
                                                color="primary"
                                                size = "medium"
                                                onClick = {this.newItemInitHandler}
                                            > 
                                                <FaPlus style ={{fontSize:"2em"}}></FaPlus>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            
                        </Grid>
                        </Paper>
                    </div>)
                    : null
                }
                <Switch>
                <Route path = {`${this.props.match.url}/:format`} exact component = {EnvelopePreview}/>
                </Switch>
            </div>

        )
    }
}

export default withRouter(SetStyles);
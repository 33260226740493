import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'


const styles = theme => {

    return ({
        root: {
            flexGrow: 1
        },
        textField: {
            marginTop: 0, marginBottom: '-16px',
            // marginLeft: theme.spacing.unit,
            // marginRight: theme.spacing.unit,

        },

        input: {
            fontSize: '0.75em', background: 'white', color: "navy", fontWeight: 500,
            padding: '8px', paddingLeft:'15px',
            textAlign:'left',
            '&::placeholder': { color: 'blue' },
        },

        label: {
            color:'red', fontWeight:800, fontSize: '0.9em', 
            
        },
        selectRoot: {
           
                width: '100%',
                border: '1px solid grey',
                height: '32px',
                // padding: '14px',
                // background: 'darkred',
                // color:'white',
                padding:0
        },
        selectSelect: {
            // background:'yellow',
            height:'10px',
        
        }
    })
}

const FancyInput = props => {

    const { classes } = props

    return (
        <TextField
            value={props.value}
            onChange={props.onChange}
            onBlur = {props.onBlur}
            onFocus ={props.onFocus}
           

            style={{ minHeight: '3.2em' }}
            type={props.type ? props.type : 'text'}
            className={props.className ? props.className : classes.textField}
            InputProps={props.InputProps || { classes: { input: classes.input } }}
            fullWidth={true}
            variant="outlined"
            margin="dense"
            label={props.label}
            dense={classes.dense}
            step={props.step}
            placeholder={props.placeholder}
            disabled={props.disabled ? props.disabled : false}
            select={props.select}
            multiline={props.multiline}
            InputLabelProps = { {classes:{root:classes.label}}}
            SelectProps = { {classes:
                {
                    root:classes.selectRoot,
                    select: classes.selectSelect
                }
            }}
        >
            {props.children}
        </TextField>

    )
}

export default withStyles(styles)(FancyInput)
import React from 'react';
// import Modal from '../Modal/Modal';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './EditCustomer.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { sql } from '../../Utils';
import Config from '../../config';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 80,
        // backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
      },

});

class EditCustomer extends React.Component {

    state = {
        open: false,
        destinatar: true,
        isInvalid: true,
        id: '',
        name: '',
        str: '',
        nr: '',
        bl: '',
        et: '',
        sc: '',
        ap: '',
        cp: '',
        loc: '',
        com: '',
        jud: '',
        sect: '',
        tara: 'România'
    }

    componentDidUpdate() {
        if (this.state.open !== this.props.open ) {
            this.setState({...this.props, orig: {...this.props}})
        }
        
    }

    handleType = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value.toUpperCase(),
            isInvalid:  event.target.value.length === 0 ? true:false
        });
       
    };

    handleSubmit = async () => {
        let result = null;
        try {
            let item = {...this.state};
            delete(item.open)
            delete(item.destinatar)
            delete(item.isInvalid)
            delete(item.onClose)
            delete(item.classes)
            delete(item.orig)
            console.log("ITEM TO BE UPDATED!!!", item)
            result = sql.update({
                db: Config.db.name,
                table: this.state.destinatar === true ? 'dest': 'exp',
                data: item
                
            })
        } catch(err) { console.log("update error", err)}
        finally {
            result.then( r => console.log(">>>------UPDATE RESULT------->", r))
            this.props.onClose(true)
        }

    }

    handleClose = () => {
        this.setState( {open:false} )
        this.props.onClose(false)
    }

    render() {
        if ( this.props.open === true ) {
            console.log(this.props, this.state)
        }
        // console.log(this.props.open)
        const {classes} = this.props;
        // console.log("edit customer STATE:",this.state)
        // console.log("edit customer PROPS:",this.props)
        return (
                <Dialog  
                    onClose={this.handleClose} 
                    aria-labelledby="add-user-dialog" 
                    fullWidth={true}
                    maxWidth = {'lg'}
                    open = {this.props.open}>
                    <DialogTitle>
                         MODIFICARE CONTACT
                    </DialogTitle>
                    <DialogContent>
                
                        <Switch
                            checked={this.state.destinatar}
                            color='primary'
                            onChange={this.handleType('destinatar')}
                            value="destinatar"
                        />
                        <span 
                            className={!this.state.destinatar ? 'outlined' : 'not-outlined'}
                            onClick = { () => this.setState({destinatar:false})}
                        >
                            Expeditor
                        </span>
                        <span 
                            className={this.state.destinatar ? 'outlined' : 'not-outlined'} 
                            onClick = { () => this.setState({destinatar:true})}
                        >
                            Destinatar
                        </span>

                <form className={classes.container} noValidate autoComplete="off">
                    <Grid container direction="row" spacing={8}>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-name"
                                label="Nume sau denumire"
                                className={classes.textField}
                                required = {true}
                                style={{ width: '100%' }}
                                value={this.state.name}
                                onChange={this.handleChange('name')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                id="outlined-name"
                                label="Strada"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.str}
                                onChange={this.handleChange('str')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="outlined-name"
                                label="Nr."
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.nr}
                                onChange={this.handleChange('nr')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
{/* bloc, scara, etaj, ap */}
                         <Grid item xs={2}>
                            <TextField
                                id="outlined-name"
                                label="Bloc"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.bl}
                                onChange={this.handleChange('bl')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                id="outlined-name"
                                label="Sc."
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.sc}
                                onChange={this.handleChange('sc')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>  
                        <Grid item xs={2}>
                            <TextField
                                id="outlined-name"
                                label="Et."
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.et}
                                onChange={this.handleChange('et')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>  
                        <Grid item xs={2}>
                            <TextField
                                id="outlined-name"
                                label="Ap."
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.ap}
                                onChange={this.handleChange('ap')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>     
                        <Grid item xs={4}>
                            <TextField
                                id="outlined-name"
                                label="Cod postal"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.cp}
                                onChange={this.handleChange('cp')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>                                                                                           
{/* localitatea, comuna */}
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-name"
                                label="Localitatea"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.loc}
                                onChange={this.handleChange('loc')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid> 
                        <Grid item xs={6}>
                            <TextField
                                id="outlined-name"
                                label="Comuna"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.com}
                                onChange={this.handleChange('com')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid> 
{/* judet, sector, tara                        */}
                       <Grid item xs={4}>
                            <TextField
                                id="outlined-name"
                                label="Judetul"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.jud}
                                onChange={this.handleChange('jud')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid> 
                        <Grid item xs={4}>
                            <TextField
                                id="outlined-name"
                                label="Sectorul"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.sect}
                                onChange={this.handleChange('sect')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid> 
                        <Grid item xs={4}>
                            <TextField
                                id="outlined-name"
                                label="Tara"
                                className={classes.textField}
                                style={{ width: '100%' }}
                                value={this.state.tara}
                                onChange={this.handleChange('tara')}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>   
                        
                        <Grid item xs={12} style = {{textAlign:'right'}}>
                            <Button 
                                color="secondary"
                                variant = "outlined" 
                                size = "large" 
                                onClick = {this.handleClose}>
                                INCHIDE
                            </Button>
                            &nbsp;
                            <Button 
                                color="primary"
                                variant = "outlined" 
                                size = "large" 
                                disabled = {!!this.state.isInvalid}
                                onClick = {this.handleSubmit}>
                                CONFIRMĂ !
                            </Button>
                            
                        </Grid>                       
                    </Grid>
                </form>
            </DialogContent>
            </Dialog>
       
        )
    }

}
export default withStyles(styles)(EditCustomer);
import React from 'react';
import { withRouter, Link, Route } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToolbarTop from '../Layout/ToolbarTop/ToolbarTop';
import Button from "@material-ui/core/Button";
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import LabelsLeftPanel from './LabelsLeftPanel';
import './Labels.css';
import AddressLabel from '../Envelope/AddressLabel';
import { FaWindows } from 'react-icons/fa';

const styles = theme => ({
    root: { flexGrow: 1 },
    toolbar: {
        background: 'black'
    }, 
    paper: {
        padding: theme.spacing.unit*2,
        margin: theme.spacing.unit*0.9
    }
})

function PrintElem(elem)
{
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    mywindow.document.write('<html><head><title></title>');
    mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    mywindow.document.write(document.getElementById(elem).innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.print();
    mywindow.close();

    return true;
}

class Labels extends React.Component {

    state = {
        isLoaded: false, 
        cols: 3, rows: 10, type: 'dest', sizeX: 53, sizeY: 28, left: 5, right: 10, top:5, bottom:5, orientation: 'portrait',
        presets: [
            {name: 'labels1', sizeX: 90, sizeY: 50, cols:3, rows:4, lefyt: 2, vPadding: 2, orientation: 'landscape'},
            {name: 'labels2', sizeX: 100, sizeY: 50, cols:3, rows:4, hPadding: 2, vPadding: 2, orientation: 'landscape'},
            {name: 'labels3', sizeX: 70, sizeY: 50, cols:3, rows:4, hPadding: 2, vPadding: 2, orientation: 'landscape'},
        ], 
        content: this.props.location.state.dest
        
    }

    componentWillReceiveProps(newProps) {
        console.log("new props:", newProps)
    }

    linkHandler = name => event => {
        this.props.history.push(name)
    }

    changeHandler = name => event => {
        console.log("name:", name, " event: ", event.target.value)
        this.setState({[name]:event.target.value})
    }

    orientationHandler = name => event => {
        this.setState({orientation: name})
    }

    render() {
        console.log("LABELS PROPS:", this.props);
        console.log("LABELS STATE:", this.state);

        const { classes } = this.props;
        return (
            <Grid container direction = "column" justify = "center" alignItems = "stretch">
            {/* toolbar top */}
                <Grid item className = "no-print">
                    <ToolbarTop>
                        <div>
                            {/* toolbar buttons left */}
                            <Button 
                                variant = "outlined" size="small" color = "primary"
                                onClick = { this.linkHandler('/')} >
                                HOME
                            </Button>
                                
                        </div>
                        <div>
                            {/* toolbar buttons right */}
                            <Button 
                                variant = "outlined" size="small" color = "primary"
                                onClick = { () => window.print()} >
                                PRINT
                            </Button>
                        </div>
                    </ToolbarTop>
                </Grid>

                {/* left panel */}
                <Grid item xs = {12} className = "no-print">
                    <Paper className = {classes.paper}>
                        
                        <LabelsLeftPanel {...this.state}
                            changeHandler = { this.changeHandler }
                            orientationHandler = { this.orientationHandler }
                        />
                    </Paper>
                </Grid>                

                {/* main content */}
                {/* <Grid item>
                    <Route path = {`${this.props.match.url}/:format`} exact component = {Labels}/> */}
                    <Grid container direction = "row" justify = "center" alignItems = "stretch">

                        {/* main panel */}
                        <Grid item xs={9}>
                           
                                <div className = {this.state.orientation }>
                                    <Grid container direction = "row" justify = "space-between" alignItems='center'>
                                        { this.state.content.map( (e,i) => (
   
                                            <Grid item key = {"lab-"+i}>
                                            {/* { i === 0 || (i+1)%this.cols === 0  ? ( <Grid container direction = "row"> ) : null } */}
                                                        
                                                            <AddressLabel {...e.item} box = {{
                                                            border:'0.05em dotted black', 
                                                            height: this.state.sizeY+'mm', 
                                                            width: this.state.sizeX+'mm', 
                                                            fontSize:'8px', borderRadius: 0,
                                                            marginTop: this.state.top+'px',
                                                            marginBottom: this.state.bottom+'px',
                                                            marginLeft: this.state.left+'px',
                                                            marginRight: this.state.right+'px',
                                                             
                                                             }}/>

                                           </Grid>

                                        ))}
                                        
                                    </Grid>
                                </div>

                        </Grid>
                    </Grid> 
                </Grid>
                    
            // </Grid>
        )
    }
}

export default withRouter(withStyles(styles)(Labels));

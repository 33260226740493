import React from 'react';
// import ReactDom from 'react-dom';
import { withRouter } from 'react-router-dom';
import {Checkbox, Tooltip, CircularProgress, Typography, Button, Hidden, Paper, Grid, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FaPlus, FaEnvelopeOpen, FaReplyAll, FaCog, FaTimesCircle, FaQuestionCircle, FaTimes, FaAddressCard, FaPowerOff } from 'react-icons/fa';
import SelCustomer from './SelCustomer/SelCustomer';
import ToolbarTop from '../Layout/ToolbarTop/ToolbarTop';
import SecondToolbar from '../Layout/SecondToolbar/SecondToolbar';
import MainContent from '../Layout/MainContent/MainContent';
import AddCustomer from '../AddCustomer/AddCustomer';
import EditCustomer from '../EditCustomer/EditCustomer';
import "./CustomersOrig.css";
import { sql } from '../../Utils';
import Config from '../../config';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Presets from '../../components/Presets'




import alasql from 'alasql';

const styles = theme => ({
    root: {},
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
})

class Customers extends React.Component {

    state = { 
        ...this.props, 
        search: '',
        addModal: false,
        editModal: false,
        initial: { ...this.props },
        msg:{open:false, caller: null, onClose: ()=>{}},
        selectAll: false,
        loading: false,
        currentItem : null,
        presetsOpen: false
    }

    componentWillMount() {
        let ls = localStorage.getItem(Config[process.env.NODE_ENV]['NAMESPACE'])
        if (ls) {
            ls = JSON.parse(ls)
            console.log(ls)
             this.setState({user: ls})
        }
    }

    repopulate = async () => {
        let dest = null, exp = null, selected = null, selExp = null, selDest = null;
        try {
            dest = await sql.select({ db: Config.db.name, table: 'dest', data: 'all' })
            exp = await sql.select({ db: Config.db.name, table: 'exp', data: 'all' })
            selected = await sql.select({ db: Config.db.name, table: 'selected', data: 'all' })
        } catch (err) { console.log(err) }
        finally {
            this.setState({ dest: dest, exp: exp, initial: { dest: dest, exp: exp } });
            if (selected.length > 0) {
                selected.map((e, i) => {
                    if (e.item) {
                        selected[i].item = this.state[e.type].filter(elem => elem.id === e.item.id)[0];
                    }
                })
            }
            sql.freestyle(Config.db.name, 'DELETE FROM selected WHERE id NOT NULL')
                .then(r => {
                    sql.insert({ db: Config.db.name, table: 'selected', data: selected }).then(rs => {
                        console.log("selected was updated. result...", rs);
                        this.setState({ selected: selected, editModal: false })
                    })
                })
        }
    }

    contactHandler = (event, type) => async () => {
        let result = null;
        // daca este expeditor, sterge toti expeditorii din selected si apoi insereaza event-ul
        if (type === 'exp') {
            sql.remove({ db: Config.db.name, table: 'selected', data: { key: 'type', value: 'exp' } })
        }

        // if is not there already, add it
        if (this.state.selected.filter(e => e.selectedId === event.id).length === 0) {
            try {
                result = await sql.insertGetId({
                    db: Config.db.name,
                    table: 'selected',
                    data: { selectedId: event.id, type: type, item: event }

                })
            } catch (err) { console.log("err:", err); }
            finally {
                // console.log(result)
                // let selected = [...this.state.selected];
                // selected.push({
                //     id: result,
                //     selectedId: event.id,
                //     type: this, 
                //     item: event
                // })
                // console.log(selected)
                // this.setState({selected: selected})
                sql.select({ db: Config.db.name, table: 'selected', data: 'all' }).then(r => this.setState({ selected: r }))
            }
        }
         else {
            //else remove it (does not matter wheather is dest of exp)
            try {
                await sql.remove({
                    db: Config.db.name,
                    table: 'selected',
                    data: { key: 'selectedId', value: event.id }
                })
            } catch (err) { console.log("err", err) }
            finally {
                sql.select({ db: Config.db.name, table: 'selected', data: 'all' }).then(r => this.setState({ selected: r }))
            }

        }

        // this.setState({ search: '', ...this.state.initial })

    }

    unselectHandler = event => {
        sql.remove({
            db: Config.db.name,
            table: 'selected',
            data: {
                key: 'selectedId',
                value: event.id
            }
        }).then(r => {
            sql.select({ db: Config.db.name, table: 'selected', data: 'all' })
                .then(rs => this.setState({ selected: rs }))
        })

    }

    unselectAllDestHandler = () => {
        this.state.selected.map(e => {
            if (e.type === 'dest') {
                sql.remove({
                    db: Config.db.name,
                    table: 'selected',
                    data: {
                        key: 'id',
                        value: e.id
                    }
                })
            }
        })
        sql.select({ db: Config.db.name, table: 'selected', data: 'all' }).then(r => this.setState({ selected: r }))
        this.setState({ selected: [] })

    }

    unselectExpHandler = () => {

    }

    openEditModalHandler = (item, type) => {
        this.setState({ editModal: true })
        type = type === 'dest' ? true : false;
        item.destinatar = type;
        this.setState({ currentItem: item })
        this.setState({ editModal: true })
    }

    updateModalHandlerOnClose = async (event) => {

        let exp = null, dest = null, elem = null;
        if (event === true) {
            try {
                exp = await sql.select({ db: Config.db.name, table: 'exp', data: 'all' });
                dest = await sql.select({ db: Config.db.name, table: 'dest', data: 'all' })

            } catch (err) { return err; }
            finally {
                this.setState({ exp: exp, dest: dest, initial: {dest:dest, exp:exp} });

                // update the items in selected table accordingly
                let selected = [...this.state.selected];
                selected.map((e, i) => {
                    if (e.type === 'dest') {
                        elem = dest.filter(el => el.id === e.selectedId)
                        if (elem.length > 0) {
                            selected[i].item = elem[0]
                        }
                    } else {
                        elem = exp.filter(el => el.id === e.selectedId)
                        if (elem.length > 0) {
                            selected[i].item = elem[0]
                        }
                    }

                })
                sql.freestyle(Config.db.name, 'DELETE FROM selected WHERE id NOT NULL')
                    .then(r => {
                        sql.insert({ db: Config.db.name, table: 'selected', data: selected }).then(rs => { this.setState({ selected: selected }) })
                    })
            }

        }
        this.setState({ editModal: false, inital: { exp: this.state.exp, dest: this.state.dest } })
        this.repopulate()
    }

    searchHandler = (ev) => {
        if ( this.state.search === '' ) { this.setState({dest: this.state.initial.dest, loading: false})}
        this.setState({ search: ev.target.value, loading: true })
        setTimeout( ()=> {
            if (!this.state.initial) { this.setState({ initial: [...this.props.dest] }) }
            // let filtered = this.props.dest.filter(e => e.name.toUpperCase().indexOf(ev.target.value.toUpperCase()) !== -1)
            let filtered = alasql(`select * from ? where name like "%${this.state.search}%"`, [this.state.dest])

            this.setState({ dest: filtered, loading: false })
            
        }, 1200)
        
    }

    linkHandler = name  => event => {
        this.props.history.push({
            pathname: name,
            state: { dest: this.selDest, exp: this.selExp }
        })
    }

    addContactHandler = async value => {
        console.log("value", value)
        this.setState({ addModal: false })
        if (!!value) {
            let dest = null, exp = null, sel = null;
            try {
                dest = await sql.select({ db: Config.db.name, table: 'dest', data: 'all' })
                exp = await sql.select({ db: Config.db.name, table: 'exp', data: 'all' })
                sel = await sql.select({ db: Config.db.name, table: 'selected', data: 'all' })
            } catch (err) { console.log("err", err) }
            finally {

                sel.map((e, i) => {
                    let item = [...e.type].filter(elm => elm.id === e.selectedId);
                    if (item.length > 0) {
                        sel[i].item = item[0]
                        console.log("found sel item", item[0])
                    }
                })
                console.log("sel",sel,"dest:", dest)
                this.setState({ dest: dest, exp: exp, initial: {dest:dest, exp:exp}, currentItem:null })
            }
        }
    }

    deleteHandler =  (item, type) => {
        const onClose = async event  => { 
            if (!event) { this.setState({msg:{open:false}}) } else {

                let r = null, s = null;
                try {
                    r = await sql.remove({ db: Config.db.name, table: type, data: { key: 'id', value: item.id } });
                    s = await sql.remove({ db: Config.db.name, table: 'selected', data: { key: 'selectedId', value: item.id } })
                } catch (err) { console.log(err) }
                finally {
                    this.repopulate();
                    this.setState({msg:{open:false}})
                }
            }}
        this.setState({msg: {
            open:true, 
            caller: 'deleteHandler',
            onClose: onClose
        }})
    }

    selectAllHandler = () => {
        this.setState({selectAll: !this.state.selectAll, loading: true})
        if ( this.state.selectAll === true ) {
            sql.remove({db: Config.db.name, table: 'selected', data: 'all'})
            .then( async ()=> {
                let dests =[];
                [...this.state.dest].map( e => { dests.push( {type:'dest', selectedId: e.id, item: e}) })

                try {
                  
                    await sql.insert({
                            db: Config.db.name, 
                            table: 'selected', 
                            data: dests
                        })
                    
                } catch(err) { console.log(err)}
                finally {
                    sql.select({db: Config.db.name, table: 'selected', data: 'all'})
                        .then( r => this.setState({selected: r, loading: false}))
                }
            })
            
        } else {
            sql.remove({db: Config.db.name, table: 'selected', data: 'all'}).then( r => this.setState({selected: [], loading: false}))
        }

    }

    presetsShow = () => {
        this.setState({presetsOpen: true})
    }

    presetsOnClose = dest => {
        console.log("dest:", dest)
        let { selected } = this.state
        if ( dest ) {
            let data = []
            
            dest.data.map( elem => {
                data.push({
                    selectedId: elem.id,
                    item: elem,
                    type: 'dest'
                })
            })

            console.log("closed data:", data)
            let exp = selected.filter( item => item.type = 'exp')
            // sql.remove({db: Config.db.name, table: 'selected', data: 'all'})
            //     .then( () => {
            //         sql.insert({db: Config.db.name, table: selected, data:data})
            //             .then( () => {
            //                 this.setState({selected: [...data, ...exp],  dest: data, presetsOpen: false})
            //             })
                    
            //     })
            this.setState({selected: [...data, ...exp]})
        } 
        this.setState({presetsOpen: false})
        

        
    }
    

    render() {
        const {classes} = this.props; 
        const { presetsOpen, dest, selected } = this.state;
        // console.log("STATE OF CUSTOMERS:", this.state)
        this.selExp = [...this.state.selected].filter(e => e.type === 'exp');
        this.selDest = [...this.state.selected].filter(e => e.type === 'dest');

        //selected dest / exp

        return (
            <div style={{ border: '0px dotted navy', marginRight: '0px' }} id="top">
                <div id="modal-placeholder"></div>
                <AddCustomer open={this.state.addModal} onClose={this.addContactHandler} />
                <EditCustomer
                    {...this.state.currentItem}
                    open={this.state.editModal}
                    onClose={event => this.updateModalHandlerOnClose(event)}
                />
                <ConfirmDialog {...this.state.msg} />
                { this.state.loading ? 
                     <CircularProgress status="loading" 
                     style = {{
                         color: 'red',
                         position: 'fixed',
                         top: '50%',
                         left: '50%',
                         marginTop: '-50px',
                         marginLeft: '-50px',
                         zIndex: 9999
                     }}/>
                     : null }

                <ToolbarTop>
                    <div>Expeditor &nbsp;
                    <span>
                            {
                                this.state.exp.map((elem, index) => (
                                    <Button key={'item-' + index}
                                        name={elem.name}
                                        style={{ marginRight: '10px' }}
                                        size="small"
                                        variant={
                                            (this.state.selected.filter(e => (e.selectedId === elem.id && e.type === 'exp'))).length > 0
                                                ? 'contained'
                                                : 'outlined'}
                                        color={
                                            (this.state.selected.filter(e => (e.selectedId === elem.id && e.type === 'exp'))).length > 0
                                                ? 'primary'
                                                : 'default'}
                                        onClick={this.contactHandler(elem, 'exp')}
                                        onContextMenu = {(event)=> { event.preventDefault();this.deleteHandler(elem, 'exp')}}

                                    >
                                        <small>{elem.name}</small>

                                    </Button>
                                ))
                            }

                        </span>
                        <div></div>

                    </div>

                    <div>
                    <Button variant = "contained" size = "small" color = "secondary" onClick = {this.presetsShow} disabled={true}>
                        SETURI PREDEFINITE
                    </Button>
                    &nbsp; &nbsp;
                         <small> 
                        {this.state.user 
                        ?  <span> {this.state.user.name}  &nbsp; ({ this.state.user.email })</span>
                        : null}
                    
                        </small>
                    
                    <Link to={{
                        pathname: '/settings', 
                        data: { ...this.state }
                    }}>
                        <Button variant="text" color="primary" size="small" >
                            <FaCog style={{ fontSize: '1.3em' }} />
                            {/* <Hidden only={'xs'}><strong>&nbsp;&nbsp;CONFIGURĂRI &nbsp;&nbsp;</strong></Hidden> */}
                        </Button>
                    </Link>
                    <Link to = "/about">
                        <Button variant="text" color="primary" size="small" >
                            <FaQuestionCircle style={{ fontSize: '1.2em' }} />
                            {/* <Hidden only={'xs'}><strong>&nbsp;&nbsp;CONFIGURĂRI &nbsp;&nbsp;</strong></Hidden> */}
                        </Button>  
                    </Link>  
                    <Button variant = "text" color="primary" size = "small" onClick = {()=> {
                        localStorage.removeItem(Config[process.env.NODE_ENV]['NAMESPACE']);
                        document.location = Config[process.env.NODE_ENV]['OAUTH_APP']
                    }}>
                        <FaPowerOff style={{ fontSize: '1.2em' }} />
                    </Button>
                     </div>
                </ToolbarTop>

                <SecondToolbar>
                    <Hidden only={['xs', 'sm']}>
                        <div>Destinatari ({this.state.dest.length})
    
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                                className="input"
                                value={this.state.search}
                                onChange={this.searchHandler}
                                placeholder="cauta..."
                            />
                            { this.state.search.length > 0 
                            ? (<Button 
                            variant = "text"
                            color = "secondary"
                            size = "small"
                            style = {{marginLeft: '-20px'}}
                            onClick = {()=> {
                                this.setState({search:'', dest: this.state.initial.dest})
                            }}>
                                <FaTimes style = {{fontSize: '1.5em'}}/>
                            </Button>)
                            : null

                            }

                            { this.state.dest.length <= 200 && this.state.dest.length !== 0 
                            ? (<Tooltip title = "SELECTEAZĂ-I PE TOȚI">
                                <span>
                                <Checkbox
                                    checked = {this.selDest.length === this.state.dest.length}
                                    onChange = {this.selectAllHandler}
                                />SELECTEAZĂ-I PE TOȚI
                                </span>
                            </Tooltip>)
                            : null 
                            }
                            
                        </div>
                        
                    </Hidden>

                    <span>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{ marginRight: '10px' }}
                            // style = {{float:'right', marginRight: "90px"}} 
                            // className="btn-default-format"
                            onClick={() => { this.setState({ addModal: true }) }}>
                            &nbsp;<FaPlus style={{ fontSize: '14px' }} />
                            <Hidden only={'xs'}><a> &nbsp;&nbsp; ADAUGĂ NOU &nbsp;&nbsp;</a></Hidden>
                        </Button>

                        { this.selExp.length > 0 && this.selDest.length > 0 
                            ?
                            <span>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: '10px' }}
                                    onClick={this.linkHandler('/borderou')}>
                                    &nbsp;&nbsp;<FaEnvelopeOpen style={{ fontSize: '14px' }} />
                                    <Hidden only={'xs'}><a > &nbsp;&nbsp; BORDEROU &nbsp;&nbsp;</a></Hidden>
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: '10px' }}
                                    onClick={this.linkHandler('/receipt')}>
                                    &nbsp;<FaReplyAll style={{ fontSize: '14px' }} />
                                    <Hidden only={'xs'}><a> &nbsp;&nbsp;CONFIRMĂRI &nbsp;&nbsp;</a></Hidden>

                                </Button>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: '10px' }}
                                    onClick={this.linkHandler('/envelope')}>
                                    &nbsp;<FaEnvelopeOpen style={{ fontSize: '14px' }} />
                                    <Hidden only={'xs'}><a > &nbsp;&nbsp; PLICURI &nbsp;&nbsp;</a></Hidden>
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    style={{ marginRight: '10px' }}
                                    onClick={this.linkHandler('/labels')}>
                                    &nbsp;<FaAddressCard style={{ fontSize: '14px' }} />
                                    <Hidden only={'xs'}><a > &nbsp;&nbsp; ETICHETE &nbsp;&nbsp;</a></Hidden>
                                </Button>                                

                            </span>
                            : null
                        }
                    </span>
                </SecondToolbar>
                <MainContent>
                    <Grid item>
                    <Paper className= {classes.paper} style = {{height:'200px', overflow:'auto'}}>
                        <Grid container direction = "row">
                        { this.state.dest.length < 1000
                            ? [...this.state.dest].map((elem, index) => (
                                <Button 
                                    name={elem.name}
                                    key = {'btnx-'+index}
                                    size='small'
                                    style={{  width: '100%', textAlign:'left', padding:0, margin:0 }}
                                    variant={
                                        (this.state.selected.filter(e => (e.selectedId === elem.id && e.type === 'dest'))).length > 0
                                            ? 'contained'
                                            : 'outlined'}
                                    color={
                                        (this.state.selected.filter(e => (e.selectedId === elem.id && e.type === 'dest'))).length > 0
                                            ? 'primary'
                                            : 'default'}
                                    onClick={this.contactHandler(elem, 'dest')}
                                    onContextMenu = {(event)=> { event.preventDefault();this.deleteHandler(elem, 'dest')}}
                                >
                                   <span style = {{width: '98%', textAlign:'left'}}> 
                                    {/* {elem.name} */}
                                        <Grid container direction="row">
                                        <Grid item sm = {3} lg = {4} xs = {12}><strong>{elem.name}</strong></Grid>
                                        <Hidden only = 'xs'>
                                            <Grid item>
                                                <small>
                                                    Adresa: 
                                                    str. {elem.str}, nr. {elem.nr}, bl. {elem.bl}, sc. {elem.sc}, et. {elem.et}, ap. {elem.ap}
                                                    loc. {elem.loc}, com. {elem.com}, jud. {elem.jud}, cod poștal {elem.cp}, țara {elem.tara}
                                                </small>
                                            </Grid>
                                        </Hidden>
                                        </Grid>
                                   </span>

                                </Button>
                                // </Grid>

                            )) 
                            
                            : <Paper style = {{marginTop: "10px", marginBottom: '-20px', padding: "2%", width: "100%"}}> 
                                <Typography variant = 'body1'>
                                    Am găsit prea multe contacte ({this.state.dest.length}).<br/>
                                        Pentru a păstra o performanța optimă a încărcării se vor afișa maxim 1000 de contacte.<br/><br/>
                                        Vă rugăm să folosiți opțiunea de căutare pentru a filtra rezultatele după nume

                                </Typography>
                             </Paper>

                        }
                        </Grid>
                        </Paper>
                        </Grid>
                        <Grid item>
                        <Paper className={classes.paper}>
                        <div style={{ textAlign: 'left' }}>
                            <Typography variant = "overline">Expeditor: </Typography> 
                            
                            <Grid container>
                                {this.selExp.length > 0
                                    ? <SelCustomer
                                        {...this.selExp[0]}
                                        width="260px"
                                        confirmDialogOpen={this.state.confirmDialogOpen}
                                        unselect={this.unselectHandler}
                                        editHandler={value => this.openEditModalHandler(value, 'exp')}
                                        deleteHandler={value => this.deleteHandler(value, 'exp')}

                                    />
                                    : 'neselectat'}
                            </Grid>
                            {/* {JSON.stringify(this.selExp)} */}
                            <br />
                            <div>
                                <Typography variant = "overline">Destinatari
                                {this.selDest.length > 0
                                    ? (<span>({this.selDest.length})
                                    </span>)
                                    : null}
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={this.unselectAllDestHandler}  >
                                    {this.selDest.length > 0
                                        ? <small><FaTimesCircle /> &nbsp;&nbsp; elimină întreaga selecție</small>
                                        : 'neselectati'}
                                </Button>
                                </Typography> 
                                
                                {this.selDest.length > 0
                                    ? (
                                        <Grid container style={{ width: '100%' }}>
                                       {/* {JSON.stringify(this.state.dest, 1, null)} */}
                                            {this.selDest.map((e, i) =>
                                                <SelCustomer key={'seldest-' + i}
                                                    {...e}
                                                    confirmDialogOpen={this.state.confirmDialogOpen}
                                                    width="250px"
                                                    unselect={this.unselectHandler}
                                                    editHandler={value => this.openEditModalHandler(value, 'dest')}
                                                    deleteHandler={value => this.deleteHandler(value, 'dest')}

                                                />

                                            )}

                                        </Grid>
                                    ) : null
                                }

                            </div>

                        </div>
                    {/* </div> */}
                    </Paper>
                    </Grid>
                </MainContent>
                {/* <Presets open = { presetsOpen } onClose =  {this.presetsOnClose} data = {{ dest: dest, selected: this.selDest}} /> */}
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Customers));
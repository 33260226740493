import React, { Component } from 'react'
import { withStyles, Paper, Grid } from '@material-ui/core'

const styles = theme => ({
    root: { flexGrow: 1},
    paper: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit,
        
    }

})


class Styles extends Component {
    render() {
        const { classes } = this.props
        return (
            <div className = {classes.root}>
                <Paper className = { classes.paper } style = {{height: '75vh'}}>
                    <Grid container direction = "row" spacing = {8}>
                        <Grid item xs = {5} >
                            <Paper className = {classes.paper} style = {{height: '70vh'}}>
                                settings
                            </Paper>
                        </Grid>
                        <Grid item xs = {7} >
                            <Paper className = {classes.paper} style = {{height: '70vh'}}>
                                previzualizare
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper> 
            </div>
        )
    }
}


export default withStyles(styles)(Styles)